/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { withNamespaces } from 'react-i18next';
import { isMobile } from 'react-device-detect';

function PopupContent({ onClose, t }) {
  return (
    <div style={{ overflow: "scroll", textAlign: "center", maxWidth: !isMobile ? 500 : '100%', width: isMobile ? '100%' : undefined }}>
      <a className="close" onClick={() => onClose(false)}>
        &times;
        </a>
      <div dangerouslySetInnerHTML={{ __html: t('informationalBox') }}>

      </div>
    </div >
  );
}

export default withNamespaces()(PopupContent);
