export default {
    en: {
        translation: {
            "homepage": "Home",
            "benefits": "Benefits",
            "priceList": "Price List",
            "warranty": "Warranty",
            "ourDoctors": "Our Doctors",
            "contacts": "Contacts",
            "dentistry": "Dentistry",
            "toothFillings": "Fillings",
            "scaleRemoval": "Tooth scale removal",
            "inlay": "Inlay",
            "crown": "Tooth crown",
            "endodontics": "Endodontics",
            "restorations": "Restorations",
            "whitening": "Tooth whitening",
            "xray": "X-ray",
            "digitalPanoXray": "Digital 3D Panorama X-ray",
            "digitalFullXray": "Digital 3D Full X-ray",
            "digitalPeriapicalXray": "Digital periapical X-ray",
            "tmjCT": "TMJ CT",
            "oralSurgery": "Oral Surgery",
            "orthodontics": "Orthodontics",
            "periodontics": "Periodontics",
            "homeopathy": "Homeopathy",
            "orthopedy": "Orthopedy",
            "dermatology": "Dermatology",
            "urology": "Urology",
            "childNeurology": " Child Neurology",
            "occupationalPhysician": "Occupational Physician",
            "guestrooms": "Guestrooms",
            "openingHours": "Opening Hours",
            "sunday": "Sunday",
            "monday": "Monday",
            "tuesday": "Tuesday",
            "wednesday": "Wednesday",
            "thursday": "Thursday",
            "friday": "Friday",
            "saturday": "Saturday",
            "closed": "Closed",
            "checkoutPrices": "Check out our prices",
            "fogHigenikus": "OUR CLINICAL DENTAL HYGIENEISTS",
            "fogSzakaszisztens": "DENTAL ASSISTANTS",
            "coordinators": "Patient Coordinators",
            "ourTeam": "Our Team",
            "privacy": "Privacy Policy",
            "homeDisclamer": "We have been waiting for our adult and children patients in Pomáz for 10 years now at the gate of Pilis. We provide an absolute patient-centered care in a friendly, familiar enviroment with a highly trained, precise medical team.",
            "bookApointment": "Book an appointment",
            "appointmentDisclamer": "If you are unable to arrive at your appointment, please advise us at least 24 hours in advance to facilitate the appointment of other patients. Thank You!",
            "appointmentDisclamerAddition": "In the event of cancellation within 24 hours, we are forced to charge a standby fee of HUF 10,000. Please notify us of cancellations by e-mail or SMS.",
            "healthFunds": "Contracted Health Funds",
            "telephone": "TELEPHONE",
            "reachPhone": "You can reach us at phone. Call us on <a href='tel: +3626343093'>06 26 343-093</a> or a <a href='tel:+ 36204331931'>06 20 433-1931</a> phone number. Our collegues will happy reply to you.",
            "addressDisclamer": "On workdays, From Monday to Friday, 08:00-20:00. <br /> Our doctors individually working hours.",
            "contactBox": "We would like to notify our guests, we can't accept reservation trought our Facebook site.",
            "ourAddress": "Our Address",
            "address": "Fog-Ház Plussz Kft <br /> H- 2013 Pomáz, Beniczky street 4",
            "informationalBox": `<p align="center"> <strong> Projekt azonosítószáma </strong> VEKOP-5.1.1-5-1-2-16-2017-00001 </p>
            <p align="center"> <strong> Kedvezményezett neve: </strong> FOG-HÁZ PLUSSZ Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság </p>
            <p align="center"> <strong> Projekt címe: </strong> A FOG-HÁZ PLUSSZ Kft. ingatlanjának komplex energetikai korszerűsítése </p>
            <p align="center"> <strong> Vissza nem térítendő támogatás összege: </strong> 8 277 721 Ft </p>
             <p align="center"> <strong> mértéke: </strong> 44, 99 % </p>
             <p align="center"> <strong> Projekt leírása: </strong> A projekt keretében cégünk a pomázi (2013 Pomáz, Beniczky utca 4., hrsz.: 662) telephelyen található, 691,2 m2 fűtött alapterületű fogászati klinika komplex energetikai korszerűsítését tervezi megvalósítani. Sor kerül egyrészt energiahatékonysági fejlesztések megvalósításara (homlokzatszigetelés, nyílászáró szerkezetek cseréje), illetve 19,76 kw napelemes rendszer telepítésére. Eredményképp BB minősítésű, energetikailag korszerű épületet kapunk. </p>
            <p align="center"> <strong> A projekt összes elszámolható költsége: </strong> 18 399 023 Ft </p>
            <p align="center"> <strong> Visszatérítendő hitel összege:  </strong> 8 289 560 Ft </p>
            <p align="center"> <strong> Projekt befejezése: </strong> 2019. augusztus 28. </p>
            `,
            'emergencyDisclamer': `Dear Patients,
Our price list used during the Emergency Response differs from the ones used so far due to the special circumstances!
Thank you for your understanding!`
        }
    },
    hu: {
        translation: {
            "homepage": "Kezdőlap",
            "benefits": "Ellátások",
            "priceList": "Árlista",
            "warranty": "Garancia",
            "ourDoctors": "Doktoraink",
            "contacts": "Kapcsolat",
            "dentistry": "Fogászat",
            "toothFillings": "Fogtömés",
            "scaleRemoval": "Fogkő Eltávolítás",
            "inlay": "Fogbetét (Inlay)",
            "crown": "Korona",
            "endodontics": "Endodoncia",
            "restorations": "Pótlások",
            "whitening": "Fogfehérítés",
            "xray": "Röntgen",
            "digitalPanoXray": "Digitális 3D Panoráma rtg",
            "digitalFullXray": "Digitális 3D Tele rtg",
            "digitalPeriapicalXray": "Digitális Periapicalis rtg",
            "tmjCT": "Állkapocsízület CT",
            "oralSurgery": "Szájsebészet",
            "orthodontics": "Fogszabályozás",
            "periodontics": "Parodontológia",
            "homeopathy": "Homeopátia",
            "orthopedy": "Ortopédia",
            "dermatology": "Bőrgyógyászat",
            "urology": "Urológia",
            "childNeurology": " Gyermek Neurológia",
            "occupationalPhysician": "Üzemorvos",
            "guestrooms": "Vendégszobák",
            "openingHours": "Nyitvatartás",
            "sunday": "Vasárnap",
            "monday": "Hétfő",
            "tuesday": "Kedd",
            "wednesday": "Szerda",
            "thursday": "Csütörtök",
            "friday": "Péntek",
            "saturday": "Szombat",
            "closed": "Zárva",
            "checkoutPrices": "Tekintse meg árainkat",
            "fogHigenikus": "KLINIKAI FOGÁSZATI HIGIÉNIKUSAINK",
            "fogSzakaszisztens": "FOGÁSZATI SZAKASSZISZTENSEINK",
            "coordinators": "Páciens Koordinátoraink",
            "ourTeam": "Csapatunk",
            "privacy": "Adatvédelmi tájékoztató",
            "homeDisclamer": " Pomázon a Pilis kapujában immár tíz évevárjuk felnőtt és gyermek pácienseinket.Barátságos, családias környezetben magasan képzett, precíz, pontos orvosi csapattal abszolút páciens központú ellátást biztosítunk.",
            "bookApointment": "Időpontfoglalás",
            "appointmentDisclamer": "Kérjük, hogy ha nem tud eljönni az Ön által foglalt vizsgálati időpontra, szíveskedjék azt minimum a vizsgálat előtt 24 órával előbb jelezni, elősegítve ezzel más páciensek időpont foglalását! Köszönjük!",
            "appointmentDisclamerAddition": "24 órán belüli lemondás esetén 10 000 Ft készenléti díjat vagyunk kénytelenek felszámolni. Ídőpont lemondást kérjük e-mailben vagy SMS formában jelezzék nekünk.",
            "healthFunds": "Szerződött egészségpénztárak",
            "telephone": "TELEFON",
            "reachPhone": "Telefonon érhet el a legegyszerűbben. Hívjon a <a href='tel: +3626343093'>06 26 343-093</a> vagy a <a href='tel:+ 36204331931'>06 20 433-1931</a> számon, és munkatársaink örömmel fogadják hívását.",
            "addressDisclamer": "Munkanapokon, Hétfőtől Péntekig, 08:00-tól 20:00-ig. <br /> Orvosaink egyéni rendszerint dolgoznak.",
            "contactBox": "Felhívjuk tisztelt betegeink figyelmét, hogy sajnálatos módon a Facebook oldalunkról nem tudunk időpontot egyeztetni.",
            "ourAddress": "Címünk",
            "address": "Fog-Ház Plussz Kft <br /> H- 2013 Pomáz, Beniczky utca 4",
            "informationalBox": `<p align="center"> <strong> Projekt azonosítószáma </strong> VEKOP-5.1.1-5-1-2-16-2017-00001 </p>
            <p align="center"> <strong> Kedvezményezett neve: </strong> FOG-HÁZ PLUSSZ Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság </p>
            <p align="center"> <strong> Projekt címe: </strong> A FOG-HÁZ PLUSSZ Kft. ingatlanjának komplex energetikai korszerűsítése </p>
            <p align="center"> <strong> Vissza nem térítendő támogatás összege: </strong> 8 277 721 Ft </p>
             <p align="center"> <strong> mértéke: </strong> 44, 99 % </p>
             <p align="center"> <strong> Projekt leírása: </strong> A projekt keretében cégünk a pomázi (2013 Pomáz, Beniczky utca 4., hrsz.: 662) telephelyen található, 691,2 m2 fűtött alapterületű fogászati klinika komplex energetikai korszerűsítését tervezi megvalósítani. Sor kerül egyrészt energiahatékonysági fejlesztések megvalósításara (homlokzatszigetelés, nyílászáró szerkezetek cseréje), illetve 19,76 kw napelemes rendszer telepítésére. Eredményképp BB minősítésű, energetikailag korszerű épületet kapunk. </p>
            <p align="center"> <strong> A projekt összes elszámolható költsége: </strong> 18 399 023 Ft </p>
            <p align="center"> <strong> Visszatérítendő hitel összege:  </strong> 8 289 560 Ft </p>
            <p align="center"> <strong> Projekt befejezése: </strong> 2019. augusztus 28. </p>

            `,
            'emergencyDisclamer': `Kedves Pácienseink!
A Sürgősségi Ügyelet alatt alkalmazott árlistánk a speciális körülményekre való tekintettel eltér az eddig alkalmazottaktól!
Megértésüket köszönjük!`
        }
    },
    de: {
        translation: {
            "homepage": "Startseite",
            "benefits": "Verpflegung",
            "priceList": "Preisliste",
            "warranty": "Garantie",
            "ourDoctors": "Arzt",
            "contacts": "Kontakt",
            "dentistry": "Zahnmedizin",
            "toothFillings": "Plombe",
            "scaleRemoval": "Zahnsteinentfernung",
            "inlay": "Inlay",
            "crown": "Zahnkrone",
            "endodontics": "Wurzelbehandlung",
            "restorations": "Zahnersetz",
            "whitening": "Bleaching",
            "xray": "Röntgen",
            "digitalPanoXray": "Digitales 3D Panoramaröntgen",
            "digitalFullXray": "Digitales 3D voll Röntgen",
            "digitalPeriapicalXray": "Digitales Periapicalis Röntgen",
            "tmjCT": "Kiefergelenk CT",
            "oralSurgery": "Stomatologie",
            "orthodontics": "Kiederorthopädie",
            "periodontics": "Paradontologie",
            "homeopathy": "Homöopathie",
            "orthopedy": "Orthopädie",
            "dermatology": "Dermatologie",
            "urology": "Urologie",
            "childNeurology": "Kinderneurologie",
            "occupationalPhysician": "Betriebsarzt",
            "guestrooms": "Gastzimmer",
            "openingHours": "Öffnungszeiten",
            "sunday": "Sonntag",
            "monday": "Montag",
            "tuesday": "Dienstag",
            "wednesday": "Mittwoch",
            "thursday": "Donnerstag",
            "friday": "Freitag",
            "saturday": "Samstag",
            "closed": "Geschlossen",
            "checkoutPrices": "Tekintse meg árainkat",
            "fogHigenikus": "KLINIKAI FOGÁSZATI HIGIÉNIKUSAINK",
            "fogSzakaszisztens": "FOGÁSZATI SZAKASSZISZTENSEINK",
            "coordinators": "Patientkoornitatoren",
            "ourTeam": "Csapatunk",
            "privacy": "Datenschutzhinweise",
            "homeDisclamer": "Wir warten schon seit 10 Jahren in Paumasch, am Tor von Pilis, auf unsere Patienten - sowohl auf Erwachsene als auch auf Kinder -. Wir bieten patientenorientierte Versorgung in einer freundlichen, familiären Umgebung mit einem hochqualifizierten und präzisen medizinischen Team. ",
            "bookApointment": "Terminvereinbarung",
            "appointmentDisclamer": "Wenn Sie nicht zu Ihrem Termin kommen können, teilen Sie uns dies bitte mindestens 24 Stunden im Voraus mit, um den Termin für andere Patienten zu erleichtern. Vielen Dank!",
            "appointmentDisclamerAddition": "Im Falle einer Stornierung innerhalb von 24 Stunden müssen wir eine Bereitschaftsgebühr von 10.000 HUF berechnen. Bitte teilen Sie uns Stornierungen per E-Mail oder SMS mit.",
            "healthFunds": "Vertraglich vereinbarte Krankenkassen",
            "telephone": "TELEFON",
            "reachPhone": "Der einfachste Weg zu erreichen ist per Telefon.Rufen Sie <a href='tel: +3626343093'> 06 26 343-093 </a> oder <a href='tel: +36204331931'>06 20 433-1931</a> an und unsere Mitarbeiter freuen sich über Ihren Anruf",
            "addressDisclamer": "An Wochentagen von Montag bis Freitag von 08:00 bis 20:00 Uhr. <br/> Unsere Ärzte arbeiten normalerweise individuell.",
            "contactBox": "Bitte beachten Sie, dass wir auf unserer Facebook-Seite keinen Termin vereinbaren können.",
            "ourAddress": "Adresse",
            "address": "Fog-Ház Plussz Kft <br /> H- 2013 Pomáz, Beniczky Straße 4",
            "informationalBox": `<p align="center"> <strong> Projekt azonosítószáma </strong> VEKOP-5.1.1-5-1-2-16-2017-00001 </p>
            <p align="center"> <strong> Kedvezményezett neve: </strong> FOG-HÁZ PLUSSZ Kereskedelmi és Szolgáltató Korlátolt Felelősségű Társaság </p>
            <p align="center"> <strong> Projekt címe: </strong> A FOG-HÁZ PLUSSZ Kft. ingatlanjának komplex energetikai korszerűsítése </p>
            <p align="center"> <strong> Vissza nem térítendő támogatás összege: </strong> 8 277 721 Ft </p>
             <p align="center"> <strong> mértéke: </strong> 44, 99 % </p>
             <p align="center"> <strong> Projekt leírása: </strong> A projekt keretében cégünk a pomázi (2013 Pomáz, Beniczky utca 4., hrsz.: 662) telephelyen található, 691,2 m2 fűtött alapterületű fogászati klinika komplex energetikai korszerűsítését tervezi megvalósítani. Sor kerül egyrészt energiahatékonysági fejlesztések megvalósításara (homlokzatszigetelés, nyílászáró szerkezetek cseréje), illetve 19,76 kw napelemes rendszer telepítésére. Eredményképp BB minősítésű, energetikailag korszerű épületet kapunk. </p>
            <p align="center"> <strong> A projekt összes elszámolható költsége: </strong> 18 399 023 Ft </p>
            <p align="center"> <strong> Visszatérítendő hitel összege:  </strong> 8 289 560 Ft </p>
            <p align="center"> <strong> Projekt befejezése: </strong> 2019. augusztus 28. </p>

            `,
            'emergencyDisclamer': `Liebe Patienten
Unsere Preisliste, die während der Notfallreaktion verwendet wird, unterscheidet sich aufgrund der besonderen Umstände von der bisher verwendeten!
Vielen Dank für Ihr Verständnis`
        }
    }
}