export default [{
  name: "Rubás Tünde",
  image: require('../images/szakaszisztens/image001.jpg')
},
{
  name: "Husztey Réka",
  image: require('../images/szakaszisztens/huszteireka.jpeg'),
},
{
  name: "Toma Alexandra",
  image: require('../images/szakaszisztens/tomaalexandra.jpeg'),
},
{
  name: "Csőkör Jordána",
  image: require('../images/no-photo.png'),
}
]