import { Component } from "react";
import { withRouter } from "react-router-dom";

class CloseMenu extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.props.closeMenu();
    };
  };

  render() {
    return this.props.children;
  }
}

export default withRouter(CloseMenu);