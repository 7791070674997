export default [
    {
        name: "DR. ZALÁNYI KATALIN",
        image: require("../images/doctors/zalanyikatalin.jpg"),
        hu: {
            role: "ORVOS-IGAZGATÓ FOGSZAKORVOS",
            boldDisclamer: `Gyermekkoromban én is féltem a fogászati beavatkozásoktól, 
        ezért is akartam egy ilyen stressz mentes, 
        barátságos környezetű rendelőt kialakítani`,
            disclamer: `1992-ben végeztem a Semmelweis Orvostudományi 
            Egyetemen.
            Az egyetem után a Kőbányai Gyermekfogászaton és 
            magánrendelőben 
            dolgoztam. 1998-ban tettem fog- és szájbetegségek 
            szakvizsgát. 2000 óta 
            magánrendelőben várom szeretettel pácienseimet.
            Fontosnak tartom a folyamatos továbbképzéseket,
             mivel a fogászat
            is egy igen intenzíven fejlődő ága az orvostudománynak,
            így 2016-ban új
            szakvizsgát tettem konzerváló fogászat és fogpótlástan 
            szakterületen.`
        },
        en: {
            role: "DOCTOR- DIRECTOR-DENTAL SPECIALIST",
            boldDisclamer: `When I was a kid, I was afraid of dental procedures, so I wanted to create such a stress-free, friendly environment`,
            disclamer: `I graduated in 1992 from the Semmelweis University of Medicine. After university I worked at the Kőbánya Children's Dentistry and private clinic. In 1998, I took a special exam for dental and oral diseases. I have been waiting for my patients in private clinic since 2000. I consider continuous training to be important, as dentistry is a very intensive branch of medicine, so in 2016 I took a new exam in the field of preserving dentistry and dentistry.`
        },
        de: {
            role: "ARZT-LEITENDER FACHZAHNARZT",
            boldDisclamer: `Als ich ein kleines Kind war, hatte ich auch Angst vor zahnärztlichen Eingriffe. Deswegen habe ich mich dafür entschieden, eine Praxis in einer stressfreien und freundlichen Umgebung zu schaffen.`,
            disclamer: `Ich habe mein Medizinstudium an der Semmelweis Universität im Jahre 1992 abgeschlossen. Nach dem Abschluss meines Studiums habe ich in der Kőbányai Gyermekfogászat (Kinderzahnheilkunde) und in Privatpraxis gearbeitet. Im Jahre 1998 habe ich eine Fachprüfung für Zahn- und Munderkrankungen abgelegt. Seit 2000 warte ich auf meine Patienten in meiner Privatpraxis. Ich halte die regelmäßigen Weiterbildungen wichtig, da auch die Zahnmedizin ein solcher Zweig der Medizin ist, die rasch entwickelt. Aus diesem Grund habe ich im Jahre 2016 eine neue Fachprüfung im Fachgebiet für konservierende Zahnmedizin und Zahnprothetik abgelegt.`
        }
    },
    {
        name: "Dr. Osztie Anna",
        image: require("../images/doctors/osztieanna.jpeg"),
        hu: {
            role: "FOGORVOS, Fogpótlástan rezidens",
            disclamer: "Diplomámat 2023-ban szereztem a Semmelweis Egyetem Fogorvostudományi Karán summa cum laude minősítéssel. Fontosnak tartom a folyamatos fejlődést és tanulást, ahhoz hogy pácienseimnek szakszerű ellátást nyújthassak. Ezért az egyetem elvégzése után fogpótlástan szakképzést kezdtem el. A fogpótlások készítése során célom, hogy a pácieseimnek örömet szerezzek, és mind funkcionálisan, mind esztétikailag javítsak fogazati állapotukon. Munkámat nagy örömmel végzem, számomra a fogászatban az alkotás és kreativitás ötvöződik a gyógyítással. A kezelések során arra törekszem, hogy barátságos és stresszmentes légkört alakítsak ki, és a páciensek elégedetten szálljanak ki a székből."
        },
        en: {
            role: "Dentist, resident in prosthodontics",
            disclamer: "I graduated summa cum laude from the Semmelweis University Faculty of Dentistry in 2023. I consider continuous development and learning important in order to provide professional care to my patients. Therefore, after graduating from university, I started vocational training in dental prosthodontics. When making dental restorations, my goal is to bring joy to my patients and to improve their dental condition both functionally and aesthetically. I do my work with great pleasure, for me, creation and creativity combine with healing in dentistry. During the treatments, I strive to create a friendly and stress-free atmosphere, so that the patients get out of the chair satisfied."
        },
        de: {
            role: "Zahnarzt, Assistenzarzt für Prothetik",
            disclamer: "Im Jahr 2023 schloss ich mein Studium an der Fakultät für Zahnmedizin der Semmelweis-Universität mit summa cum laude ab. Für die professionelle Betreuung meiner Patienten halte ich die kontinuierliche Weiterentwicklung und das Lernen für wichtig. Deshalb habe ich nach meinem Universitätsabschluss eine Ausbildung zur Zahnprothetikerin begonnen. Mein Ziel bei der Herstellung von Zahnrestaurationen ist es, meinen Patienten Freude zu bereiten und ihren Zahnzustand sowohl funktionell als auch ästhetisch zu verbessern. Ich mache meine Arbeit mit großer Freude, für mich verbinden sich Kreation und Kreativität mit Heilung in der Zahnheilkunde. Während der Behandlungen lege ich Wert darauf, eine freundliche und stressfreie Atmosphäre zu schaffen, damit die Patienten zufrieden aus dem Behandlungsstuhl aufstehen."
        }
    },
    {
        name: "DR. NAGY ZSANETT",
        image: require("../images/doctors/nagyzsanett.jpg"),
        hu: {
            role: "FOGSZAKORVOS",
            disclamer: `2007-ben végeztem a Semmelweis Egyetem Fogorvostudományi Karán, majd a 
            Fogpótlástani Klinikán kezdtem meg fogorvosi munkám.
            A szakvizsga megszerzését követően (2010) klinikai orvosként részt veszek a 
            betegellátásban,magyar és angol nyelvű hallgatók oktatásában.
            
            Egyetemi tevékenység mellett 2008 óta magánrendelőkben szereztem szakmai 
            tapasztalatot. A Fogház Plussz Egészségügyi Centrum munkatársaként 2009 óta dolgozom. 
            Ahhoz, hogy a pácienseinknek a legjobb ellátást tudjuk nyújtani folyamatos fejlődésre van 
            szükség, ezért rendszeresen részt veszek hazai és külföldi szakmai továbbképzéseken.        
            `,
            listTitle: "Szakterületek:",
            listItems: ["Esztétikai fogászat",
                "Konzerváló fogászat",
                "Esztétikus tömések",
                "Endodoncia",
                "Fogpótlások készítése",
                "Rögzített és kivehető fogpótlások",
                "Implantációs fogpótlások"
            ]
        },
        en: {
            role: "DENTAL SPECIALIST",
            disclamer: `In 2007 I graduated from the Faculty of Dentistry of Semmelweis University, then started my dental work at the Dental Clinic. After taking the exam (2010), as a clinical doctor, I take part in patient care and in the education of Hungarian and English students. Besides university activities, since 2008 I have gained professional experience in private clinics. I have been working at the Prison Plus Health Center since 2009. In order to provide our patients with the best care, we need continuous improvement, so I regularly attend in-service training courses at home and abroad.`,
            listTitle: "Practice Areas:",
            listItems: ["Aesthetic dentistry fogászat",
                "Preservative dentistry",
                "Aesthetic fillings",
                "Endodontics",
                "Preparation of dentures",
                "Fixed and removable dentures",
                "Implant replacements"
            ]
        },
        de: {
            role: "FACHZAHNARZT",
            disclamer: `Im Jahre 2007 habe ich mein Studium an der Zahnmedizinischen Fakultät der Semmelweis Universität abgeschlossen, danach habe ich als Zahnarzt in der Fogpótlástani Klinika gearbeitet. Nachdem ich (im Jahre 2010) meine Fachprüfung abgelegt habe, nehme ich mich als klinischer Arzt an der Patientenversorgung teil und ich unterrichte sowohl ungarische als auch englische Studenten. Neben meinem Studium an der Universität habe ich seit 2008 in Privatpraxen gearbeitet und währenddessen habe ich berufliche Erfahrungen gesammelt. Seit 2009 arbeite ich in der Fogház Plussz Egészségügyi Centrum. Wir sollen uns kontinuierlich entwickeln, um die bestmögliche Versorgung für unsere Patienten anzubieten. Aus diesem Grund nehme ich mich regelmäßig an in- und ausländischen Berufsweiterbildungen teil. `,
            listTitle: "Fachgebiete:",
            listItems: ["Ästhetische Zahnmedizin",
                "Konservierende Zahnmedizin",
                "Ästhetische Füllungen",
                "Endodontologie",
                "Anfertigung von Zahnersätze",
                "Festsitzende und herausnehmbare Zahnersätze",
                "Implantate"
            ]
        }
    },
    {
        name: "DR. KOLOZSVÁRY LÁSZLÓ",
        image: require("../images/doctors/kolozsvarylaszlo.jpg"),
        hu: {
            role: "FOGSZAKORVOS, IMPLANTOLÓGUS",
            disclamer: `Szakterületem az implantológia és a protetika 
            (koronák, hidak, rögzített és kivehető fogsor) 
            és kisebb szájsebészeti beavatkozások.
             1987-ben végeztem a SOTE-n. 1996-ig az SE Fogpótlástani Klinikán
             dolgoztam tanársegédként. Németországban egy évig kutatóként
             dolgoztam. Rendszeresen veszek részt továbbképzéseken, a legújabb 
            módszerek megismerése céljából különböző nemzetközi
             konferenciákon (pl. USA).                   
            `
        },
        en: {
            role: "DENTAL SPECIALIST, Implantologist",
            disclamer: `I specialize in implantology and prosthetics (crowns, bridges, fixed and removable dentures) and minor oral surgery. I graduated from SOTE in 1987. Until 1996 I worked as a teaching assistant at the SE Dental Clinic. I worked as a researcher in Germany for a year. I attend regular training courses at various international conferences (eg USA) to learn about the latest techniques`
        },
        de: {
            role: "Fachzahnarzt, Implantologe ",
            disclamer: `Zu meinen Fachgebieten gehört die Implantologie, die Prothetik (Kronen, Brücken, festsitzende und herausnehmbare Zahnprothesen) und kleinere zahnchirurgische Eingriffe. Ich habe mein Studium an der Semmelweis Universität im Jahre 1987 abgeschlossen. Bis 1996 habe ich als Lehrassistent in der Fogpótlástani Klinika an der Semmelweis Universität gearbeitet. Ich habe als Forscher ein Jahr in Deutschland gearbeitet. Ich nehme mich regelmäßig an Berufsweiterbildungen und an verschiedenen internationalen Konferenzen (z. B. in den USA) teil, um die neuesten Methoden kennenzulernen.`
        }
    },
    {
        name: "DR. ZAKLANOVICS HELGA",
        image: require("../images/doctors/zaklanovicshelga.jpg"),
        hu: {
            role: "FOGSZAKORVOS, FOGSZABÁLYOZÁS",
            listItems: [
                "1992: Semmelweis Orvostudományi Egyetem Fogorvostudományi Kar",
                "1994: Fog, és Szájbetegségek szakvizsga",
                "2004: Fogszabályozás szakvizsga",
                "Munkahelyek:",
                "1992-2001 Semmelweis Egyetem Konzerváló Fogászati Klinika",
                "2001-2008 Semmelweis Egyetem Kútvölgyi Klinikai Tömb - Fogszabályozási Részleg",
                "2008- magánrendelő"
            ]

        },
        en: {
            role: "DENTAL SPECIALIST, Orthodontics",
            listItems: [
                "1992: Semmelweis University, Faculty of Dentistry",
                "1994: Teeth And Oral Diseases Specialist Exam",
                "2004: Orthodontics Exam",
                "Workplaces:",
                "1992-2001 Semmelweis University Conservative Dental Clinic",
                "2001-2008 Semmelweis University Kútvölgy Clinical Block - Department of Orthodontics",
                "2008- Private Practice"
            ]
        },
        de: {
            role: "FACHZAHNARZT, KIEFEROTRHOPÄDIE",
            listItems: [
                "1992: Semmelweis Universität, Zahnmedizinische Fakultät",
                "1994: Fachprüfung für Zahn- und Munderkrankungen",
                "2004: Fachprüfung für Kieferorthopädie",
                "Arbeitsplätze:",
                "1992-2001 Konzerváló Fogászati Klinika an der Semmelweis Universität",
                "2001-2008 Kútvölgyi Klinikai Tömb an der Semmelweis Universität – Abteilung für Kieferortopädie",
                "2008- Privatpraxis"
            ]
        }
    },

    {
        name: "DR. KOVÁCS MÓNIKA",
        image: require("../images/doctors/kovacsmonika.jpg"),
        hu: {
            role: "FOGSZAKORVOS",
            disclamer: `1992-ben végeztem a Semmelweis Egyetem Fogorvostudományi Karán. Az egyetem elvégzése után először szülőként próbáltam ki magam, és csak 4 gyermek születése után kezdtem orvosként dolgozni. Fog- és szájbetegségek szakvizsgát 2003-ban tettem.
            Budapesten a Villányi Dent Fogászati Központban alkalmazottként, majd a Bartók Béla úti rendelőben magánorvosként dolgoztam. 
            2008-ban nagy örömmel csatlakoztam a Fogház Plussz Egészségügyi Centrum alapító csapatához. 
            Már gyerekkoromban is erre a pályára készültem keresztanyám példája nyomán, azóta is az emberekkel való törődést és a gyógyítást tartom a legfontosabbnak.
            Általános fogorvoslással foglalkozom, tehát konzerváló fogászattal (tömések, gyökérkezelések) és fogpótlással (hidak, fogsorok              készítése) egyaránt.
                  Immár 5 gyermekes anyaként nagy szeretettel várom kisgyermek             pácienseimet is.                      
           `
        },
        en: {
            role: "DENTAL SPECIALIST",
            disclamer: `In 1992 I graduated from the Faculty of Dentistry of Semmelweis University. After graduating from university I first tried myself as a parent and started working as a doctor only after the birth of 4 children. I took the special exam for dental and oral diseases in 2003. I worked as an employee at the Villányi Dent Dental Center in Budapest, then as a private doctor at the Bartók Béla út office. In 2008, I was delighted to join the founding team of the Prison Plus Health Center. Already in my childhood, I was preparing for this career, following the example of my godmother, and ever since, I have given priority to caring for people and healing. I deal with general dentistry, that is conservative dentistry (fillings, root treatments) and dentures (bridges, dentures). Now, as a mother of 5, I look forward to seeing my toddler patients.`

        },
        de: {
            role: "FACHZAHNARZT",
            disclamer: `Ich habe mein Studium an der Zahnmedizinischen Fakultät der Semmelweis Universität im Jahre 1992 abgeschlossen. Nach meinem Studium habe ich mich als Elternteil mit der Familie beschäftigt. Nachdem ich vier Kinder auf die Welt gebracht habe, habe ich mich zuerst dafür entschlossen, als Fachzahnarzt angefangen zu arbeiten. Im Jahre 2003 habe ich die Fachprüfung für Zahn- und Munderkrankungen abgelegt. Ich habe als Angestellte in der Villányi Dent Fogászati Központ in Budapest, danach als Privatarzt in der Praxis auf der Béla Bartók Straße gearbeitet. Im Jahre 2008 habe ich mit großer Freude zu dem Gründungsteam der Fogház Plussz Egészségügyi Centrum beigetreten. Ich habe mich schon seit meiner Kindheit nach dem Vorbild meiner Patin auf diese Karriere vorbereitet. Seitdem halte ich die Fürsorge und die Heilung der Menschen für das wichtigste. Ich beschäftige mich mit der allgemeinen Zahnheilkunde, das heißt mit der konservierenden Zahnmedizin (Füllungen, Wurzelbehandlungen) und auch mit Zahnprothetik (Anfertigung von Brücken und Prothesen). Jetzt als Mutter von fünf Kindern sind auch die kleinen Patienten auch in meiner Praxis herzlich willkommen.`
        }
    },
    {
        name: "Dr. Kovács Vanda",
        image: require('../images/doctors/drkovacsvanda.jpg'),
        hu: {
            role: 'FOGORVOS',
            disclamer: `Fogorvosi Diplomámat 2021-ben szereztem a Semmelweis Egyetem Fogorvostudományi karán. Főbb érdeklődési területeim közé tartozik a restauratív fogászat, tömések illetve fogpótlások készítése, a gyermekfogászat és a fogszabályozás. Munkám során fontos számomra a kezelések jó hangulata, a páciensek félelem és fájdalommentes ellátása valamint teljeskörű elégedettsége.`
        },
        en: {
            role: 'DENTIST',
            disclamer: `I graduated with a degree in Dentistry in 2021 from the Faculty of Dentistry at Semmelweis University. My main areas of interest include restorative dentistry, making fillings and dentures, pediatric dentistry and orthodontics. In the course of my work, the good mood of the treatments, the fearless and painless care of the patients, and their complete satisfaction are important to me.`
        },
        de: {
            role: 'ZAHNARZT',
            disclamer: `Mein Studium der Zahnmedizin habe ich 2021 an der Fakultät für Zahnmedizin der Semmelweis Universität abgeschlossen. Meine Hauptinteressensgebiete sind die restaurative Zahnheilkunde, Herstellung von Füllungen und Prothesen, Kinderzahnheilkunde und Kieferorthopädie. Bei meiner Arbeit sind mir die gute Laune bei den Behandlungen, die angst- und schmerzfreie Betreuung der Patienten und deren vollste Zufriedenheit wichtig.`
        }
    },
    {
        name: "Dr. Varga Krisztina",
        image: require('../images/doctors/drvargakrisztina.jpg'),
        hu: {
            role: 'FOGSZAKORVOS',
            listItems: [
                "1992. SOTE Fogorvosi diploma",
                "1994. Fog-és szájbetegségek szakvizsga",
                "2004 óta magánrendelőben dolgozom",
                "2004-06 MHOE Homeopathiás tanulmányok",
                "2006. Konzerváló fogászat és Fogpótlástan szakvizsga",
                "2006-07 CEDH Klinikai Homeopathia- Diploma",
                "2014-16 ILK Lelkivezetői és lelkigyakorlat kísérői képzés",
                "2016-17 Sapientia Szerz. Főisk. Teológia képzés",
                "2018-19 Holisztikus Medicina craniosacralis terápia képzés",
                "2019-2021 Upledger Hung. Institute craniosacralis terápia képzés",
                "2020-2021 Integratív Pszichoterápiás Egyesület képzése",
                "2021- SE Pszichoterápia szakképzés",
                `„Emberek szolgálatában állni, segíteni és gyógyítani”- gyermekkorom óta erre készülök.
            Egészségünk a test és a lélek épsége. 30 éve végzem a fogászati munkám - testünk legkeményebb szövetének gyógyítását.És közel 10 éve gyakorlom a legfinomabb részünkkel, a lelkünkkel való foglalkozást.Csodálatos az emberi szervezet.`]
        },
        en: {
            role: 'DENTAL SPECIALIST',
            listItems: ["1992. SOTE Diploma in Dentistry",
                "1994. Dental and oral diseases exam",
                "I have been working in a private practice since 2004",
                "2004 - 06 MHOE Homeopathic Studies",
                "2006. Conservative Dentistry and Prosthodontics Exam",
                "2006 - 07 CEDH Clinical Homeopathy - Diploma",
                "2014 - 16 ILK Pastoral and Spiritual Practice Accompanying Training",
                "2016 - 17 Sapientia Author.College.Theology training",
                "2018 - 19 Holistic Medicine craniosacral therapy training",
                "2019 - 2021 Upledger Hung.Institute of craniosacral therapy training",
                "2020 - 2021 Training of the Integrative Psychotherapy Association",
                "2021 - SE Psychotherapy vocational training",
                `Serving people, helping and healing” - I have been preparing for this since I was a child.
            Our health is the integrity of body and soul.I have been healing the hardest tissue in my dental body for 30 years.And I have been practicing dealing with our finest part, our soul, for almost 10 years.The human body is amazing.`]
        },
        de: {
            role: 'FACHZAHNARZT',
            listItems: [
                "1992. SOTE - Diplom in Zahnmedizin",
                "1994. Prüfung auf Zahn- und Mundkrankheiten",
                "Seit 2004 arbeite ich in eigener Praxis",
                "2004-06 MHOE Homöopathische Studien",
                "2006. Prüfung in Konservativer Zahnheilkunde und Prothetik",
                "2006-07 CEDH Klinische Homöopathie- Diplom",
                "2014 - 16 ILK Seelsorgerische und geistliche Praxisbegleitende Weiterbildung",
                "2016 - 17 Sapientia - Autor.Hochschule.Theologische Ausbildung",
                "2018 - 19 Ganzheitliche Medizin Craniosacral Therapie Ausbildung",
                "2019–2021 Upledger Hung.Institut für Craniosacral Therapie Ausbildung",
                "2020 - 2021 Fortbildung des Integrativen Psychotherapieverbandes",
                "2021 - SE Berufsausbildung Psychotherapie",
                `Menschen dienen, helfen und heilen“ – darauf bereite ich mich seit meiner Kindheit vor.
            Unsere Gesundheit ist die Integrität von Körper und Seele.Seit 30 Jahren heile ich das härteste Gewebe in meinem Zahnkörper.Und ich übe mich seit fast 10 Jahren im Umgang mit unserem schönsten Teil, unserer Seele.Der menschliche Körper ist erstaunlich.`]
        }
    },
    {
        name: "Dr. Kardos Kinga",
        image: require("../images/doctors/kardoskinga.jpeg"),
        hu: {
            role: "FOGORVOS",
            disclamer: `Diplomámat a Semmelweis Egyetem Fogorvostudományi
            Karán szereztem. Már gyermekkoromban tudtam, hogy orvos
            szeretnék lenni. A fogorvosi szakma számomra a kreativitás,
            az alkotás, az esztétika és a modern technológia ötvözetét
            jelenti, de a legfontosabbnak a segítségnyújtást tartom. Arra
            törekszem, hogy pácienseim egészségének megőrzése és
            problémáinak megoldása közben a félelmeik legyőzését is
            elősegítsem. A kezelések során barátságos, oldott légkör és
            közvetlen kapcsolat kialakítása a célom. Szakmai érdeklődési
            körömhöz tartozik az esztétikus töméskészítés, gyökérkezelés,
            fogpótlások készítése és a gyermekfogászat.
                        
           `
        },
        en: {
            role: "DENTIST",
            disclamer: `I graduated from Semmelweis University in Dentistry
            I got it at the Faculty. I already knew he was a doctor when I was a child
            I want to be. For me, the profession of dentistry is creativity,
            the combination of creation, aesthetics and modern technology
            means, but I consider the most important thing is to help. To that
            I strive to preserve the health of my patients and
            overcoming their fears while solving their problems
            help me During the treatments, a friendly, relaxed atmosphere and
            my goal is to establish a direct relationship. Professional interest
            nails include aesthetic fillings, root canal treatment,
            making dentures and pediatric dentistry.`

        },
        de: {
            role: "ZAHNARZT",
            disclamer: `Ich habe an der Semmelweis-Universität einen Abschluss in Zahnmedizin gemacht
            Ich habe es an der Fakultät bekommen. Ich wusste bereits als Kind, dass er Arzt ist
            Ich möchte sein. Der Beruf des Zahnarztes ist für mich Kreativität,
            die Kombination aus Kreation, Ästhetik und moderner Technologie
            bedeutet, aber ich denke, das Wichtigste ist, zu helfen. Dazu
            Ich bemühe mich, die Gesundheit meiner Patienten zu erhalten und
            ihre Ängste überwinden und gleichzeitig ihre Probleme lösen
            Hilf mir Während der Behandlungen herrscht eine freundliche, entspannte Atmosphäre
            Mein Ziel ist es, eine direkte Beziehung aufzubauen. Berufliches Interesse
            Nägel umfassen ästhetische Füllungen, Wurzelkanalbehandlungen,
            Herstellung von Zahnersatz und Kinderzahnheilkunde.`
        }
    },
    {
        name: "Dr. Romhányi Lili",
        image: require("../images/doctors/romhanyilili.jpg"),
        hu: {
            role: "FOGORVOS",
            disclamer: `2023-ban szereztem fogorvosi diplomámat a Semmelweis Egyetem
            Fogorvostudományi Karán. Kiskorom óta a fogorvosi pályára készülök, mely
            választásomhoz nagymértékben hozzájárultak mindig kedves, figyelmes
            fogorvosaim. Hozzájuk hasonlóan magam is nyugodt légkörű, fájdalommentes kezelésekre törekszem.
            
            Az egyetem segítségével lehetőségem volt több esztétikus fogászati
            továbbképzésen, versenyen részt venni, így vált legfőbb érdeklődési
            területemmé az esztétikus tömések, fogpótlások készítése.            
           `
        },
        en: {
            role: "DENTIST",
            disclamer: `In 2023, I obtained my dental degree at Semmelweis University
            Faculty of Dentistry. I have been preparing for a career in dentistry since I was a child
            they greatly contributed to my choice, they are always kind and attentive
            my dentists. Like them, I strive for pain-free treatments in a relaxed atmosphere.
            
            With the help of the university, I had the opportunity to do more aesthetic dentistry
            taking part in further training and competitions became his main interest
            my field is the creation of aesthetic fillings and dental restorations.`

        },
        de: {
            role: "ZAHNARZT",
            disclamer: `Im Jahr 2023 habe ich meinen Abschluss in Zahnmedizin an der Semmelweis-Universität gemacht
            Fakultät für Zahnmedizin. Ich habe mich seit meiner Kindheit auf eine Karriere in der Zahnmedizin vorbereitet
            Sie haben viel zu meiner Wahl beigetragen, sie sind immer freundlich und aufmerksam
            meine Zahnärzte. Wie sie strebe ich nach schmerzfreien Behandlungen in entspannter Atmosphäre.
            
            Mit Hilfe der Universität hatte ich die Möglichkeit, mehr ästhetische Zahnheilkunde zu betreiben
            Die Teilnahme an Weiterbildungen und Wettkämpfen wurde zu seinem Hauptinteresse
            Mein Fachgebiet ist die Herstellung ästhetischer Füllungen und Zahnrestaurationen.`
        }
    },
    {
        name: "Dr. Zoltai Kinga",
        image: require("../images/doctors/zoltaikinga.jpeg"),
        hu: {
            role: "FOGORVOS",
            disclamer: `
            2023-ban végeztem a Semmelweis Egyetemen, ahol elkötelezetté váltam az esztétikus és funkcionálisan tökéletes mosoly létrehozása iránt. Fogszabályozó rezidensi gyakorlatom során a kezelési lehetőségek széles választékával ismerkedhetek meg, amelyeken keresztül rendkívül izgalmas és gyönyörű eredményeket érhetünk el pácienseink számára. Kiemelt figyelmet fordítok a fejlődési rendellenességgel született gyermekek fogászati ellátására, mert hiszem, hogy minden gyermeknek jár a gondoskodó és szakszerű ellátás.
            A Fog-Ház Plussz Klinika orvosaként elkötelezett vagyok a pácienseim kényelme és elégedettsége iránt. Az esztétikai és gyermekfogászati panaszok kezelése során kollégáimmal együttműködve biztosítjuk a nyugodt, pácienscentrikus légkört, amely elengedhetetlen minden szakterületen a magas színvonalú szakellátás biztosításához. Forduljon hozzám bizalommal bármilyen esztétikai, illetve gyermekfogászati panasz esetén.
           `
        },
        en: {
            role: "DENTIST",
            disclamer: `I graduated from Semmelweis University in 2023, where I became committed to creating an aesthetically and functionally perfect smile. During my orthodontic residency, I got to know a wide variety of treatment options, through which we can achieve extremely exciting and beautiful results for our patients. I pay particular attention to the dental care of children born with developmental disorders, because I believe that every child deserves caring and professional care.
            As a doctor at Fog-Ház Plussz Klinika, I am committed to the comfort and satisfaction of my patients. During the treatment of aesthetic and pediatric dental complaints, in cooperation with my colleagues, we ensure a calm, patient-centered atmosphere, which is essential for ensuring high-quality professional care in all specialties. Feel free to contact me in case of any aesthetic or pediatric dental complaints.`

        },
        de: {
            role: "ZAHNARZT",
            disclamer: `Im Jahr 2023 schloss ich mein Studium an der Semmelweis-Universität ab, wo ich mich der Schaffung eines ästhetisch und funktionell perfekten Lächelns verschrieben habe. Während meiner Kieferorthopädie-Ausbildung habe ich vielfältige Behandlungsmöglichkeiten kennengelernt, durch die wir für unsere Patienten äußerst spannende und schöne Ergebnisse erzielen können. Besonderes Augenmerk lege ich auf die zahnärztliche Betreuung von Kindern mit Entwicklungsstörungen, denn ich glaube, dass jedes Kind eine fürsorgliche und professionelle Betreuung verdient.
            Als Arzt an der Fog-Ház Plussz Klinika setze ich mich für das Wohlbefinden und die Zufriedenheit meiner Patienten ein. Bei der Behandlung von ästhetischen und pädiatrischen Zahnbeschwerden sorgen wir in Zusammenarbeit mit meinen Kollegen für eine ruhige, patientenzentrierte Atmosphäre, die für die Sicherstellung einer qualitativ hochwertigen professionellen Betreuung in allen Fachgebieten unerlässlich ist. Bei ästhetischen oder pädiatrischen Zahnbeschwerden können Sie mich gerne kontaktieren.`
        }
    },
    {
        name: "Dr. Varmuzsa Edvárd Márton",
        image: require("../images/doctors/varmuzsaedvard.jpeg"),
        hu: {
            role: "FOGORVOS, Szájsebész szakorvos",
            disclamer: ``
        },
        en: {
            role: "DENTIST, Oral surgeon specialist",
            disclamer: ``

        },
        de: {
            role: "ZAHNARZT, Spezialist für Kieferchirurgie",
            disclamer: ``
        }
    },
    {
        name: "Dr. Csomó Krisztián",
        image: require("../images/no-photo.png"),
        hu: {
            role: "FOGORVOS, Szájsebész szakorvos",
            disclamer: `2012-ben végeztem a Semmelweis Egyetem Fogorvostudományi Karán. Konzerváló fogászat és Fogpótlástan, valamint Dentoalveolaris Sebészet szakvizsgákat szereztem. Ezeken felül Sugárvédelmi szakértői szakképesítéssel is rendelkezem. Jelenleg PhD fokozat védésére készülök. A fogászaton belül kiemelt érdeklődési és kutatási területem a mikroszkóp alatti beavatkozások. `
        },
        en: {
            role: "DENTIST, Oral surgeon specialist",
            disclamer: "I graduated from the Faculty of Dentistry of Semmelweis University in 2012. I obtained professional exams in Conservative Dentistry and Prosthodontics as well as Dentoalveolar Surgery. In addition, I also have a professional qualification as a radiation protection expert. I am currently preparing to defend my PhD degree. Within dentistry, my main area of interest and research is interventions under the microscope."

        },
        de: {
            role: "ZAHNARZT, Spezialist für Kieferchirurgie",
            disclamer: "Im Jahr 2012 habe ich mein Studium an der Fakultät für Zahnmedizin der Semmelweis-Universität abgeschlossen. Ich habe Fachprüfungen in konservierender Zahnheilkunde und Prothetik sowie dentoalveolärer Chirurgie abgelegt. Darüber hinaus verfüge ich auch über eine Berufsqualifikation als Strahlenschutzexperte.Ich bereite mich derzeit auf die Verteidigung meines Doktorgrades vor.Innerhalb der Zahnheilkunde liegt mein Hauptinteressen- und Forschungsgebiet bei Eingriffen unter dem Mikroskop."
        }
    },

    {
        name: "Dr. Almási Anna",
        image: require("../images/no-photo.png"),
        hu: {
            role: "Fogorvos, Szájsebész rezidens",
            disclamer: `Diplomámat 2021-ben szereztem a Semmelweis Egyetem Fogorvostudmányi karán. Főbb érdeklődési köröm már az egyetemen is a szájsebészet volt, így kezdtem el a dento-alveolaris sebész rezidensi képzést rögtön az egyemtemet követően. A Fogház Plussz Klinika orvosi csapattagjaként célom a stressz-és fájdalommentes kezelések megteremtése a legprofesszionálisabb és naprakész módszerek kivitelezésével.`
        },
        en: {
            role: "Dentist, Oral surgeon resident",
            disclamer: `I obtained my diploma in 2021 at the Faculty of Dentistry of Semmelweis University. My main area of interest was oral surgery even at university, so I started the dento-alveolar surgery residency training immediately after graduating from university. As a member of the medical team of the Fogház Plussz Klinika, my goal is to create stress- and pain-free treatments using the most professional and up-to-date methods.`

        },
        de: {
            role: "Zahnarzt, niedergelassener Kieferchirurg",
            disclamer: `Mein Diplom habe ich 2021 an der Fakultät für Zahnmedizin der Semmelweis-Universität erhalten. Mein Hauptinteressengebiet war schon während des Studiums die Oralchirurgie, daher begann ich direkt nach meinem Universitätsabschluss mit der Facharztausbildung für Dentoalveolarchirurgie. Als Mitglied des medizinischen Teams der Fogház Plussz Klinika ist es mein Ziel, mit den professionellsten und modernsten Methoden stress- und schmerzfreie Behandlungen zu ermöglichen.`
        }
    },
    {
        name: "Dr. Erdős Zsuzsanna",
        image: require("../images/doctors/erdoszsuzsanna.jpg"),
        hu: {
            role: "Parodontológus szakorvos ",
            disclamer: `Fogorvosi diplomámat a Semmelweis Egyetem Fogorvostudományi Karán szereztem 2015 - ben, majd 3 évvel később ugyanitt parodontológia szakvizsgát tettem.

2016 óta részt vettem az egyetemen fogorvostanhallgatók parodontológiai oktatásában(magyar, angol és német nyelven), a fogágybeteg páciensek ambuláns kezelésében és emellett magán rendelőben szereztem általános fogászati szakmai tapasztalatot.

A Fogház Plussz Egészségügyi Centrumban 2019 óta állok a parodontálisan érintett páciensek rendelkezésére.Közös célunk, hogy mindenkinek egyénre szabott, komplex fogászati ellátást tudjunk nyújtani, amihez elengedhetetlen az alapok rendezése, azaz az egészséges fogágy.Ennek elérésében igyekszem pácienseimnek teljeskörű szakmai segítséget nyújtani. 

`
        },

        en: {
            role: "PARODONTOLOGIST SPECIALIST",
            disclamer: `I graduated with a degree in dentistry from the Semmelweis University Faculty of Dentistry in 2015, and 3 years later I went on to do a periodontal exam.Since 2016, I have attended dental students' periodontal education at the university (in Hungarian, English and German), ambulatory treatment of dental patients, and gained general dental professional experience. Since 2019, I have been at the Prison Plus Plus Health Center for periodontally affected patients. Our common goal is to provide everyone with tailor-made, complex dental care, which requires a sound foundation that is healthy. To achieve this, I strive to provide my patients with full professional help.`
        },
        de: {
            role: "FACHZAHNARZT FÜR PARODONTOLOGIE",
            disclamer: `Ich habe mein Studium an der Zahnmedizinischen Fakultät der Semmelweis Universität im Jahre 2015 abgeschlossen. 3 Jahre später habe ich auch an der Semmelweis Universität die Fachprüfung für Parodontologie abgelegt. Seit 2016 habe ich an der Universität für die Zahnmedizinstudenten Parodontologie (auf Ungarisch, auf Englisch und auf Deutsch) und ambulante Behandlung von Patienten unterrichtet, die unter Zahnbettenerkrankungen leiden. Daneben habe ich in einer Privatpraxis allgemeine zahnärztliche Berufserfahrungen gesammelt. Ich stehe seit 2019 in der Fogház Plussz Egészségügyi Centrum für die Patienten zur Verfügung, die unter parodontalen Erkrankungen leiden. Unser gemeinsames Ziel ist, für jeden eine individuelle, komplexe zahnärztliche Behandlung zu gewährleisten. Dazu ist es unerlässlich, ein gesundes Zahnbett zu haben. Um diese zu erreichen, bemühe ich mich, für meine Patienten umfassende, professionelle Berufshilfe zu leisten.`
        }
    },
    {
        name: "DR. MOLITORISZ DÁNIEL",
        image: require("../images/doctors/molitoriszdaniel.jpg"),
        hu: {
            role: "ORTOPÉD TRAUMATOLÓGUS, GYERMEKTRAUMATOLÓGUS",
            disclamer: `A Magyar Testnevelési Egyetem testnevelő tanári szakán, majd 2003-ban a Semmelweis Orvostudományi Egyetem általános orvosi karán végeztem. Ortopéd-traumatologus szakvizsgát 2010-ben tettem. Az ortopédia-baleseti sebészeten belül a gyermekkori sérülések ellátásra specializálódtam.
            2006 óta dolgozom a Péterfy Kórház Baleseti Központjában, a gyermekosztályon. Osztályunkon gyermekkori baleseti sérülések akut ellátását végezzük, valamint gyermekek veleszületett-, szerzett mozgásszervi elváltozásainak kezelése folyik nagy számban.
            Az ortopédiai elváltozások kialakulása és a sport több ponton kapcsolódik egymáshoz. Ez ma sajnos gyakran jelenti a rendszeres mozgás-, a sport hiányát, amely a gyermekeknél is jelentős befolyásoló tényezője tartási rendellenességek, későbbi komoly elváltozások kialakulásának.
            Kifejezetten vonatkozik ez gerinc tartási rendellenességeire és már kialakult kóros elváltozásaira, valamint a lábboltozat tudatos funkcionális felépítésére.
            A már kialakult elváltozások kezelése hosszadalmas, nehéz és sokszor igényel operatív beavatkozást. A még kóros elváltozásnak nem nevezhető, de ilyen irányba mutató kezdeti tünetek jó hatásfokú kezelése kevesebb kényelmetlenséggel jár, jobb eredményességet hoz. Nagyon fontos hangsúlyozni, hogy a panaszok nem egyetlen orvos-beteg találkozástól javulhatnak, vagy szűnhetnek meg, hanem tudatosan felépített és vezetett terápiától, fejlesztéstől.
                                 
           `
        },
        en: {
            role: "ORTHOPEDIC TRAUMATOLOGY, CHILDREN'S TRAUMATOLOGY",
            disclamer: `I graduated from the Physical Education Department of the Hungarian University of Physical Education and in 2003 from the Faculty of Medicine of the Semmelweis University of Medicine. I passed the examination of an orthopedic traumatologist in 2010. Within orthopedic accident surgery, I specialize in treating childhood injuries. I have been working at the Péterfy Hospital Accident Center since 2006, in the ward. In our department we deal with the acute care of childhood accident injuries and the treatment of congenital and acquired musculoskeletal disorders in children. The development of orthopedic lesions and sports are interrelated at many points. Unfortunately, this often means the lack of regular physical activity and sports, which is a significant factor in the development of posture disorders and subsequent serious lesions in children as well. It applies specifically to postural abnormalities of the spine and to established pathological lesions, as well as to the conscious functional structure of the foot arch. Treatment of already existing lesions is lengthy, difficult and often requires operative intervention. A good treatment of the initial symptoms, which cannot be called a pathological disorder yet, leads to less discomfort and better results. It is very important to emphasize that complaints can not be healed or eliminated by a single doctor-patient encounter, but by consciously structured and guided therapy and development.`

        },
        de: {
            role: "FACHARZT FÜR ORTHOPÄDIE UND TRAUMATOLOGIE, KINDERTRAUMATOLOGE",
            disclamer: `Ich habe an der Magyar Testnevelési Egyetem (University of Physical Education) Sport auf Lehramt studiert, danach habe ich im Jahre 2003 mein anderes Studium an der Medizinischen Fakultät der Semmelweis Universität abgeschlossen. Ich habe im Jahre 2010 die Fachprüfung für Orthopädie und Traumatologie abgelegt. Ich bin in der Orthopädie und Unfallchirurgie auf die Behandlung von Verletzungen spezialisiert, die in der Kindheit passiert waren. Seit 2006 arbeite ich in der Kinderstation in dem Unfallzentrum des Péterfy Krankenhauses. In unserer Abteilung beschäftigen wir uns mit der Akutversorgung der Unfallverletzungen von Kindern und mit der Behandlung angeborener und erworbener Erkrankungen des Bewegungsapparates von Kindern. Die Entstehung orthopädischer Alterationen und der Sport hängen an vielen Stellen zusammen. Heutzutage bedeutet dies leider den Mangel an regelmäßige Bewegung und Sport, der ein wesentlicher Faktor bei der Entstehung von Haltungsstörungen und schwerwiegende Alterationen bei Kindern ist, die später auftreten können. Das bezieht sich speziell auf die Haltungsstörungen und auf die schon krankhaften Veränderungen der Wirbelsäule bzw. auf den bewussten Aufbau des Fußgewölbes. Die Behandlung bereits bestehender krankhaften Veränderungen ist weitläufig und schwierig, und diese fordert häufig operative Eingriffe an. Die gute Behandlung der ersten Beschwerden, die noch nicht als krankhafte Veränderungen gekennzeichnet werden, kann wenige Unannehmlichkeiten verursachen und wird erfolgreicher. Das ist sehr wichtig zu betonen, dass die Beschwerden nach einer einzigen Begegnung von Arzt und Patient nicht geheilt oder nicht beseitigt werden können. Die Beschwerden sollen durch bewusst aufgebaute und geleitete Therapie bzw. Entwicklung behandelt werden.`
        }
    },
    {
        name: "DR. SZABÓ ÉVA",
        image: require("../images/doctors/szaboeva.jpg"),
        hu: {
            role: "HOMEOPÁTIÁS ORVOS",
            disclamer: `1994-ben kaptam meg az orvosi diplomámat a 
            Szent-Györgyi Albert Orvostudományi Egyetemen, Szegeden. 
            Már hallgatóként is sokszor találtam szembe magam korunk 
            orvostudományának korlátaival, a kezelések okozta mellékhatásokkal.
             Ezeket a tapasztalatokat a kórházban eltöltött idő megerősítette.
             Keresni kezdtem olyan gyógyító módszereket, melyek hatékonyak és
             nem járnak együtt jelentős mellékhatásokkal.
            Így találtam rá a homeopátiára és a természetgyógyászati ágak közül a 
            fitoterápiára. 2002-ben szereztem homeopátiás diplomát a francia
            CEDH képzés keretein belül, 2008-ban pedig természetgyógyász,
            fitoterápiás képesítést.            
           `
        },
        en: {
            role: "HOMEOPATHIC DOCTOR",
            disclamer: `In 1994 I received my medical degree from the Albert Szent-Györgyi Medical University, Szeged. As a student, I have often encountered the limitations of modern medicine and the side effects of treatments. This experience was confirmed by the time spent in the hospital. I started looking for healing methods that were effective and did not have significant side effects. This is how I found homeopathy and phytotherapy from the natural medicine branch. I graduated in 2002 with a degree in homeopathy from the French CEDH program and in 2008 with a qualification in natural medicine and phytotherapy.`

        },
        de: {
            role: "HOMOÖPATHISCHER ARZT",
            disclamer: `Ich habe mein Medizinstudium an der Universität der Wissenschaften Szeged (früher: Szent-Györgyi Albert Universität Szeged) im Jahre 1994 abgeschlossen. Schon als Student war ich oft auf die Grenzen der modernen Zahnmedizin und auf die Wirkungen von Behandlungen gestoßen. Diese Erfahrungen wurden durch die Zeit verstärkt, die ich im Krankenhaus gebracht habe. Ich habe angefangen, nach solchen Heilmethoden zu suchen, die wirksam sind und keine signifikanten Nebenwirkungen ausüben. Danach habe ich mich dafür entschieden, sich mit der Homöopathie und mit der Phytotherapie aus der Naturheilkunde zu beschäftigen. Ich habe mein Homöopathie-Studium im Rahmen des französischen CEDH-Programms im Jahre 2002 abgeschlossen. Danach habe ich meine Ausbildung für Naturheilkunde und Phytotherapie im Jahre 2008 durchgeführt`
        }
    },
    {
        name: "DR. DEUTSCH NORBERT",
        image: require("../images/doctors/deutchnorbert.jpg"),
        hu: {
            role: "FOGLALKOZÁS-ORVOSTAN SZAKORVOS",
            disclamer: `1993-1999: Semmelweis Orvostudományi Egyetem, Budapest általános orvos

1997-1999: József Attila Tudományegyetem, 
Szeged egészségügyi közgazdász és menedzser

2000-2002 orvos gyakornok az Országos Munkahigiénés és 
Foglalkozás-egészségügyi Intézetben

2004- foglalkozás-orvostan szakorvos

2007- Foglalkozás-egészségügyért Alapítvány kuratóriumi tagja

Orvosi hivatásomban a prevencióra, azaz a megelőzésre 
helyezem a legfőbb hangsúlyt.

Prevenciós oktatási és tanácsadói tevékenységemet az alábbi 
területeken végzem:

Dohányzás elleni figyelemfelhívás, oktatás

Drogprevenciós oktatás

Vitaminok és táplálékkiegészítők szerepe életünkben

Táplálkozási tanácsadás

Elsősegélynyújtó tanfolyamok végzése        
           `
        },
        en: {
            role: "Occupational medicine specialist",
            disclamer: `1993-1999: Semmelweis University of Medicine, Budapest 1997-1999: Attila József University, Szeged Health Economist and Manager 2000-2002 Doctor Trainee at the National Institute of Occupational Hygiene and Health 2004- Occupational Medicine Specialist 2007- Occupational Health In my medical profession, I put the emphasis on prevention, that is, prevention. I work in prevention education and counseling in the following areas: Smoking awareness education Education Drug prevention education The role of vitamins and supplements in our lives Nutrition counseling First aid courses.`
        },
        de: {
            role: "BERUF-FACHARZT FÜR ARBEITSMEDIZIN",
            disclamer: `1993-1999: Semmelweis Universität Budapest, Facharzt für Allgemeinmedizin

1997-1999: Attila-József-Universität (Universität der Wissenschaften Szeged), Gesundheitsökonomie- und management

2000-2002: Arztpraktikant, Országos Munkahigiénés és Foglalkozás-Egészségügyi Intézet

2004- Facharzt für Arbeitsmedizin

2007- Beruf-Mitglied des Kuratoriums, Egészségügyért Alapítvány

Während meiner medizinischen Arbeit lege ich den Hauptwert auf die Prävention, also auf die Vorbeugung. Ich übe meine präventive Bildungs- und Beratungstätigkeit in den folgenden Bereichen aus: Bewusstsein für die Schädlichkeit des Rauchens, Unterricht für Drogenprävention, die Rolle von Vitaminen und Nahrungsergänzungsmitteln in unserem Leben, Ernährungsberatung, Erste-Hilfe-Kurse.      
           `
        }
    },
]