import React, { Fragment } from "react";
import { withNamespaces } from 'react-i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope,
  faPhoneAlt,
  faMap,
  faUserSecret
} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';

function Footer(props) {
  const { t } = props;
  return (
    <Fragment>
      <div className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 footer-matchheight">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 footer-item footer-item-1"></div>

                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 footer-item footer-item-2">
                  <h3 className="footer-title footer-title-line">
                    <i className="fa fa-clock-o"></i>
                    {t("openingHours")}
                  </h3>
                  <div className="opening-left">
                    {t("monday")}
                    <br />
                    {t("tuesday")}
                    <br />
                    {t("wednesday")}
                    <br />
                    {t("thursday")}
                    <br />
                    {t("friday")}
                    <br />
                    {t("saturday")}
                    <br />
                    {t("sunday")}
                  </div>
                  <div className="opening-right">
                    8:00 - 20:00
                    <br />
                    8:00 - 20:00
                    <br />
                    8:00 - 20:00
                    <br />
                    8:00 - 20:00
                    <br />
                    8:00 - 20:00
                    <br />
                    {t("closed")}
                    <br />
                    {t("closed")}
                  </div>
                </div>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-3 col-sm-3 col-xs-12 footer-item footer-item-3 footer-matchheight"
              id="footer-item-3"
            >
              <h3 className="footer-title">
                <i className="fa fa-map-marker"></i> {t("contacts")}
              </h3>
              <div className="footer-item-3-phone">
                <FontAwesomeIcon icon={faPhoneAlt} />
                <a href="tel:+3626343093"> +36-26-343-093</a>
              </div>
              <div className="footer-item-3-phone">
                <FontAwesomeIcon icon={faPhoneAlt} />
                <a href="tel:+36204331931">
                  +36-20-433-1931
                </a>
              </div>
              <div className="footer-item-3-location">
                <FontAwesomeIcon icon={faMap} />
                <a href="https://goo.gl/maps/rcQVTGP388qo86s69" target="_blank">
                  H-2013 Pomáz, Beniczky utca 4
                </a>
              </div>
              <div className="footer-item-3-mail">
                <FontAwesomeIcon icon={faEnvelope} />
                <a href="mailto:recepcio@foghazplussz.hu">
                  recepcio@foghazplussz.hu
                </a>
              </div>

              <div className="footer-item-3-mail">
                <FontAwesomeIcon icon={faFacebook} />
                <a
                  href="https://www.facebook.com/Fog-H%C3%A1z-Plussz-Kft-1730871997157745"
                  target="_blank"
                >
                  Fog-Ház Plussz Kft.
                </a>
              </div>

              <div className="footer-item-3-mail">
                <FontAwesomeIcon icon={faUserSecret} />
                <Link to={'/privacy'}>{t('privacy')}</Link>
              </div>
            </div>

            <div
              className="col-lg-3 col-md-3 col-sm-3 col-xs-12 footer-item footer-item-4 footer-matchheight"
              id="footer-item-4"
            >
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default withNamespaces()(Footer);
