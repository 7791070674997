export default [
    {
        image: require('../images/homePageBoxes/orvos.svg'),
        en: {
            title: "Higly trained doctors",
            description: "Our private practice medical team is dedicated and strives to make our patients leave satisfied!"
        },
        hu: {
            title: "Magasan képzett orvosok",
            description: "Magánrendelőnk orvosi csapata elhivatott, és törekszik arra, hogy mindig a lehető legelégedettebben távozzanak kedves pácienseink!"

        },
        de: {
            title: "HOCHQUALIFIZIERTE ÄRZTE",
            description: "Das medizinische Team unser Privatpraxis ist engagiert und strebt danach, dass unsere lieben Patienten die Praxis immer mit voller Zufriedenheit verlassen."
        }
    },
    {
        image: require('../images/homePageBoxes/szajhigenia.svg'),
        en: {
            title: "Oral Hygiene",
            description: "Proper oral hygiene is not just about protecting the health of our teeth, but it is also essential to prevent other health problems and major illnesses. Plaque on  the teeth and tartar play a major role in the development and aggravation of gingivitis, gingival atrophy and periodontal diseases."
        },
        hu: {
            title: "Szájhigiénia",
            description: "A megfelelő szájhigiénia nem csak fogaink épségének védelme -, de egyéb egészségügyi problémák, illetve komolyabb betegségek elkerülésének érdekében is alapvető fontosságú. A fogak felszínén létrejövő lepedék, és  fogkő  főszerepet  játszanak a fogínygyulladás, az ínysorvadás és a fogágybetegségek kialakulásában és súlyosbodásában."

        },
        de: {
            title: "MUNDHYGIENE",
            description: "Die richtige Mundhygiene ist von grundlegender Bedeutung, um die Unversehrtheit unserer Zähne zu schützen-, außerdem andere Gesundheitsprobleme und schwere Krankheiten zu vermeiden. Der Zahnbelag und der Zahnstein spielen eine bedeutende Rolle bei der Entstehung und Erbitterung der Zahnfleischentzündung, Zahnfleischrückgang und Zahnbetterkrankungen."
        }
    },
    {
        image: require('../images/homePageBoxes/fogko.svg'),
        en: {
            title: "Regular tartar removal",
            description: "Regular tartar removal helps maintain proper oral hygiene. The professional ultrasonic tartar removal, the polishing the surface of the teeth and the regular control ensure the smooth, plaque- and tartar free teeth."
        },
        hu: {
            title: "Rendszeres fogkő eltávolítás",
            description: "A rendszeres fogkő eltávolítás segít fenntartani a megfelelő szájhigiénét. A professzionális ultrahangos fogkő eltávolítás, a fogak felszínének polírozása, a rendszeres kontroll biztosítja a sima, lepedék és fogkő mentes fogakat."

        },
        de: {
            title: "REGELMÄßIGE ZAHNSTEINENTFERNUNG",
            description: "Die regelmäßige Zahnsteinentfernung trägt dazu bei, die richtige Mundhygiene aufrechtzuerhalten. Die professionelle Ultraschall-Zahnsteinentfernung, das Polieren der Zahnoberfläche und die regelmäßigen Kontrollen sorgen für glatte, plaque- und zahnsteinfreie Zähne. "
        }
    },
    {
        image: require('../images/homePageBoxes/implantatum.svg'),
        en: {
            title: "Implant",
            description: "A dental implant is an artificial replacement implanted in a jaw bone. Implant prosthesis will be just as strong and usable as natural teeth. It’s more natural than any other prosthetic device and results in high stability and greater chewing power by orders of magnitude, beacuse since the tooth loaded with chewing not only rests on the surface of the gums, but also integrates into the bone and with distributes the chewing forces, long-term functional capability is assured."
        },
        hu: {
            title: "Implantátum",
            description: "A fogimplantátum az állkapocscsontba ültetett mesterséges pótlás. Az implantátumra készülő fogpótlás ugyanolyan erős és használható lesz, mintha az természetes fogakra készülne. Természetesebb mint bármilyen más protetikai eszköz, nagy stabilitást eredményez és nagyságrendekkel jobb rágóképességet biztosít, mivel a rágás közben terhelt pillér fog nem csak az íny felületén támaszkodik, hanem beépül a csontozatba és a rágóerők eloszlásával, hosszútávon biztosított a funkcionális képesség."

        },
        de: {
            title: "IMPLANTAT",
            description: "Das Implantat ist ein künstlicher Ersatz, der in den Kieferknochen implantiert wird. Der Zahnersatz auf das Implantat ist genauso stark und wird genauso verwendet, als ob es auf natürliche Zähne gefertigt wäre. Es ist natürlicher, als irgendeine prothetische Hilfsmittel; es ist stabil und bietet eine viel bessere Kaufähigkeit, da der beim Kauen belastete Pfeilerzahn nicht nur auf der Oberfläche des Zahnfleisches ruht, sondern integriert sich in den Knochen, und durch die Verteilung der Kaukräfte wird die Funktionsfähigkeit langfristig gewährleistet."
        }
    },
    {
        image: require('../images/homePageBoxes/fogszab.svg'),
        en: {
            title: "Orthodontics",
            description: "A fogszabályozás a fogak és az állcsontok helyzeti és alaki rendellenességének diagnosztikájával és gyógyításával foglalkozik. A rendellenességek korai felismerésével nemcsak az első ránézésre szembetűnő esztétikai hátrányokat orvosoljuk, hanem más, esetlegesen később jelentkező elváltozásokat is meg tudjuk előzni"
        },
        hu: {
            title: "Fogszabályozás",
            description: "A fogszabályozás a fogak és az állcsontok helyzeti és alaki rendellenességének diagnosztikájával és gyógyításával foglalkozik. A rendellenességek korai felismerésével nemcsak az első ránézésre szembetűnő esztétikai hátrányokat orvosoljuk, hanem más, esetlegesen később jelentkező elváltozásokat is meg tudjuk előzni."

        },
        de: {
            title: "KIEFERORTHOPÄDIE",
            description: "Die Kieferorthopädie befasst sich mit der Diagnose und Behandlung von Positions- und Formabweichungen der Zähne und des Kiefers. Durch die frühe Erkennung der Abweichungen können nicht nur die ästhetischen Nachteile korrigiert werden, die auf den ersten Blick auffällig waren, sondern können auch andere Alterationen vorbeugt werden, die möglicherweise später auftreten können. "
        }
    }
]