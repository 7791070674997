import React from "react";
import benefits from "../constant/benefits";
import { withRouter, Redirect, Link } from "react-router-dom"
import { withNamespaces } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isMobile } from 'react-device-detect';

import { faTooth } from "@fortawesome/free-solid-svg-icons";


const Facilities = ({ t, lng, match: { params: { id } } }) => {


  const { images } = benefits[id] || {};

  const lang = lng.split("-")[0];


  return < div className="container" style={{ marginBottom: 20 }}>
    {
      benefits[id] ? (
        <div style={{ alignItems: "center" }}>
          <div className="row" style={{ justifyContent: "center", alignItems: "center" }}>
            {
              images.map(img => <img src={img} alt="test" style={{ width: "40%", margin: 20 }} />)
            }
          </div>
          <div style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>
            <h2>
              {
                benefits[id][lang] && benefits[id][lang].title
              }
            </h2>
          </div>
          <div style={{ justifyContent: "center", textAlign: "center" }} dangerouslySetInnerHTML={{
            __html: benefits[id][lang] && benefits[id][lang].disclamer
          }}>
          </div>
          <Link to="/prices#" style={{ margin: 100 }}>
            <div className="row" style={{

              boxShadow: "1px 1px 1px 1px #888888",
              height: 50,
              display: "flex",
              alignItems: "center",
              alignSelf: "center",
              width: isMobile ? "100%" : "30%",
              margin: "auto",

            }}>
              <FontAwesomeIcon icon={faTooth} style={{ width: 30, height: 30 }} />
              <h3 style={{ marginLeft: 20, marginBottom: 0 }}>
                {t('checkoutPrices')}
              </h3>

            </div>
          </Link>
        </div>
      ) : (
          <Redirect to="/" />
        )
    }
  </div >;
}

export default withNamespaces()(withRouter(Facilities));
