import React, { Fragment } from "react";

import {
  CarouselProvider,
  Slider,
  Slide
} from "pure-react-carousel";

import { isMobile } from 'react-device-detect';

import { withNamespaces } from 'react-i18next';
import HomePageBoxesArray from '../constant/homePageBoxes';
import CaruselImages from "../images/homepage"

function App(props) {
  const { lng, t } = props;

  const lang = lng.split("-")[0];

  return (
    <Fragment>
      <CarouselProvider
        naturalSlideWidth={700}
        naturalSlideHeight={500}
        totalSlides={CaruselImages.length}
        infinite={true}
        isPlaying={true}
        visibleSlides={isMobile ? 1 : 2}
      >
        <Slider>
          {
            CaruselImages.map((image, key) => (
              <Slide index={key}>
                <img
                  alt={`caruselImage-${key}`}
                  style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  src={image}
                />
              </Slide>
            ))
          }
        </Slider>
      </CarouselProvider>
      <div className="services-1" id="services">


        <div className="container">
          <div className="row">
            <p className="services-1-text">
              {
                t('homeDisclamer')
              }
            </p>

            <p style={{ color: "white", backgroundColor: "#3091d0" }}>
              {t('appointmentDisclamer')}
              <br />
              {t('appointmentDisclamerAddition')}

            </p>
            <div className="row" style={{ width: "100%", justifyContent: "center", display: isMobile ? 'contents' : 'flex' }}>
              {
                HomePageBoxesArray.map(item => (
                  <div className="col-lg-2 col-md-2 col-sm-2 col-xs-12 service">
                    <div className="service-icon" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img style={{ height: 60 }} className="flaticon-people" src={item.image} alt={item[lang].title} />
                    </div>
                    <div className="service-title">
                      <h3>{item[lang].title}</h3>
                    </div>
                    <div className="service-text">
                      {item[lang].description}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default withNamespaces()(App);
