import React, { Fragment, useState } from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "./stylesheets/master.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Home from "./pages/home";
import Services from "./pages/services";
import Prices from "./pages/prices";

import Contacts from "./pages/contacts";
import Doctors from "./pages/doctors";
import Privacy from './pages/privacy';
import { slide as Menu } from 'react-burger-menu';
import MenuItems from './components/mobileMenu';
import CloseMenu from './components/closeMenu';
import Popup from "reactjs-popup";
import PopUpContent from './components/popupContent';
import HolidayPopUpContent from './components/holidayPopupContent';
import Warranty from './pages/warranty';

function App(props) {
  const [isMenuOpen, setMenu] = useState(false);
  const [isPopUpShown, setPopUpShown] = useState(false);
  const [isHoidayPopShown, setHolidayPopShown] = useState(false);

  return (
    <Fragment>
      <Router id="outer-container">
        <CloseMenu closeMenu={() => setMenu(false)}>
          {
            isMobile &&
            <Menu outerContainerId={"root"} right isOpen={isMenuOpen}>
              <MenuItems closeMenu={() => setMenu(!isMenuOpen)} />
            </Menu>
          }
          <Header setMenuOpen={() => setMenu(!isMenuOpen)} setPopUpShown={setPopUpShown} />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/services/:id">
              <Services />
            </Route>
            <Route path="/prices">
              <Prices />
            </Route>
            {/* <Route path="/prices2">
              <Prices2 />
            </Route> */}
            <Route path="/contacts">
              <Contacts />
            </Route>
            <Route path="/doctors">
              <Doctors />
            </Route>
            <Route path="/privacy">
              <Privacy />
            </Route>
            <Route path="/warranty">
              <Warranty />
            </Route>
          </Switch>
          <Footer />
        </CloseMenu>
      </Router>
      <Popup open={isPopUpShown} contentStyle={{ maxWidth: !isMobile ? 500 : '100%', maxHeight: "100%", overflow: "scroll", width: isMobile ? '90%' : undefined }}>
        <PopUpContent onClose={setPopUpShown} />
      </Popup>
      <Popup open={false} contentStyle={{ maxWidth: '100%', maxHeight: "100%", overflow: "scroll", width: isMobile ? '90%' : undefined }}>
        <HolidayPopUpContent onClose={setHolidayPopShown} />
      </Popup>
    </Fragment>
  );
}

export default App;
