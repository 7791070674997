import React from "react";
import { withNamespaces } from 'react-i18next';

const contact = ({ t }) => {
  return <div className="container" style={{ marginTop: 20 }}>
    <h3 >{t('contacts')}</h3>
    <br></br>

    <h4 style={{ fontStyle: "italic" }}>{t('telephone')}</h4>

    <div className="contact-box" dangerouslySetInnerHTML={{ __html: t("reachPhone") }}></div>
    <br />


    <h4 style={{ fontStyle: "italic" }}>{t("openingHours")}</h4>
    <div className="contact-box" dangerouslySetInnerHTML={{ __html: t("addressDisclamer") }}>
    </div>
    <br />

    <h4 style={{ fontStyle: "italic" }}>FACEBOOK</h4>
    <div className="contact-box">
      {t("contactBox")}
    </div>
    <br />
    <h4 style={{ fontStyle: "italic" }}> {t('ourAddress')}</h4>
    <div className="contact-box" dangerouslySetInnerHTML={{ __html: t('address') }} />
    <br />
  </div >;
}

export default withNamespaces()(contact)





