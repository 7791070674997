import doctors from './doctors';


const deucthnorbert = doctors.find(item => item.name === "DR. DEUTSCH NORBERT");
const gasznerAndrea = doctors.find(item => item.name === "DR. GASZNER ANDREA");

export default {
    fogaszat: {
        images: [require("../images/benefits/fogaszat1.jpg"), require("../images/benefits/fogaszat2.jpg")],

        en: {
            title: "Dentistry",
            disclamer: "Our professionally trained dentists welcome patients with different dental problems. We work together as a real team, giving the best of every area of expertise, leaving our satisfied guests with a smile and returning regularly. Regular inspection and maintenance is extremely important to maintain a long, trouble-free, beautiful denture."
        },
        de: {
            title: "ZAHNMEDIZIN",
            disclamer: "Die Patienten, die mit verschiedenen Zahnproblemen kämpfen, sind von unseren professionell ausgebildeten Zahnärzten willkommen. Wir arbeiten als ein echtes Team zusammen und unser Team bietet das Beste aus jedem Fachgebiet, sodass unsere Gäste mit voller Zufriedenheit und mit einem Lächeln im Gesicht unsere Praxis verlassen und kehren regelmäßig nach unserer Praxis zurück. Die Inspektion und die Pflege der Zähne sind äußerst wichtig, um eine langfristige, asymptomatische, schöne Prothese zu bewahren."
        },
        hu: {
            title: "Fogászat",
            disclamer: "Szakmailag felkészült fogorvosaink szeretettel várják a különböző fogászati problémákkal érkező pácienseinket. Igazi csapatként dolgozunk össze, minden szakterület tudása legjobbját adja, hogy elégedett vendégeink mosolyogva távozzanak és térjenek vissza rendszeresen hozzánk. A rendszeres ellenőrzés és karbantartás rendkívül fontos a hosszan tartó panaszmentes, szép fogsor megőrzése érdekében."
        }
    },
    rontgen: {
        images: [require("../images/benefits/rontgen1.jpg"), require("../images/benefits/rontgen2.jpg")],
        en: {
            title: "X-RAY",
            disclamer: "Our clinic uses both small and panoramic images to determine your dental status and to ensure successful treatments."
        },
        de: {
            title: "RÖNTGEN",
            disclamer: "In unserer Praxis werden sowohl kleine Röntgenaufnahmen als auch Panoramaschichtaufnahmen erstellt, um den Zahnstatus genau zu bestimmen und die erfolgreiche Behandlung sicherzustellen. "
        },
        hu: {
            title: "RÖNTGEN",
            disclamer: "Rendelőnkben a biztos fogászati státusz megállapításához és a sikeres kezelések érdekében  kis- és panoráma felvételeket egyaránt használunk."
        }
    },
    szajsebeszet: {
        images: [require("../images/benefits/szajsebeszet1.jpg")],
        en: {
            title: "ORAL SURGERY",
            disclamer: "The equipment of our clinic and the expertise of our dental surgeon allow us to offer a complete range of outpatient dental surgeries. In many cases, a dental implant is the best aesthetic solution to replace a missing tooth. A fully ceramic tooth crown or bridge placed on a dental implant offers a complete solution."
        },
        de: {
            title: "STOMATOLOGIE",
            disclamer: "Die Ausstattung unserer Praxis und das Fachwissen unseres Zahnchirurgen ermöglichen, eine vollständige Palette ambulanter zahnchirurgischen Eingriffe anzubieten. In vielen Fällen ist ein Zahnimplantat die beste ästhetische Lösung, um einen fehlenden Zahn zu ersetzen. Eine Zahnkrone oder eine Zahnbrücke aus Keramik, die auf Zahnimplantaten befestigt werden, bieten eine komplette Lösung."
        },
        hu: {
            title: "SZÁJSEBÉSZET",
            disclamer: "Rendelőnk felszereltsége és fogsebész szakorvosunk felkészültsége lehetővé teszi, hogy az ambulánsan  elvégezhető fogsebészeti beavatkozások teljes  palettáját kínáljuk. A hiányzó fog helyettesítésére  számos esetben a fogászati implantátum a legjobb  eredményekkel járó esztétikai megoldás. A fogászati  implantátumra helyezett, teljesen kerámiából készült  fogkorona vagy híd teljes megoldást kínál. "
        }
    },
    fogszabalyozas: {
        images: [require("../images/benefits/fogszabalyozas1.jpg"), require("../images/benefits/fogszabalyozas2.jpg")],
        en: {
            title: "ORTHODONTICS",
            disclamer: "Orthodontics deals with the diagnosis and treatment of positional and shape disorders of the teeth and jaws. Not only can we correct the aesthetic drawbacks at first glance by recognizing these disorders at an early stage, but also preventing other, possibly later, lesions."
        },
        de: {
            title: "KIEFERORTHOPÄDIE",
            disclamer: "Die Kieferorthopädie befasst sich mit der Diagnose und Behandlung von Positions- und Formabweichungen der Zähne und des Kiefers. Durch die frühe Erkennung der Abweichungen können nicht nur die ästhetischen Nachteile korrigiert werden, die auf den ersten Blick auffällig waren, sondern können auch andere Alterationen vorbeugt werden, die möglicherweise später auftreten können. "
        },
        hu: {
            title: "FOGSZABÁLYOZÁS",
            disclamer: "A fogszabályozás a fogak és az állcsontok helyzeti és  alaki rendellenességének diagnosztikájával és  gyógyításával foglalkozik. A rendellenességek korai  felismerésével nemcsak az első ránézésre szembetűnő  esztétikai hátrányokat orvosoljuk, hanem más, esetlegesen később jelentkező elváltozásokat is meg tudjuk előzni."
        }
    },
    ortopedia: {
        images: [require("../images/benefits/ortopedia1.jpg")],
        en: {
            title: "ORTHOPEDIC",
            disclamer: "Orthopedic examination is the examination of the components of the musculoskeletal system (joints, muscles, tendons, bones, etc.). Diagnosis is the task of the orthopedic physician, who performs a combined analysis of a medical history, a thorough physical examination and imaging examinations."
        },
        de: {
            title: "ORTHOPÄDIE",
            disclamer: "Unter einer orthopädischen Untersuchung versteht man die Untersuchung der Bewegungsapparate (Gelenke, Muskeln, Sehnen, Knochen usw.) Die Diagnose wird durch einen Orthopäden festgestellt, die anhand der gemeinsamen Analyse der Krankengeschichte, der gründlichen körperlichen Untersuchung und auf den bildgebenden Untersuchungen (Ultraschall, MRT, Computertomographie (CT), RTG) durchgeführt wird."
        },
        hu: {
            title: "ORTOPÉDIA",
            disclamer: "Az ortopédiai vizsgálat a mozgásszervrendszer alkotó  elemeinek (ízületek, izmok, inak, csontok, stb.)  vizsgálatát jelenti. A diagnózis felállítása az ortopéd  szakorvos feladata, amit a kórelőzmény, az alapos  fizikális vizsgálat és a képalkotó vizsgálatok  (UH, MRI, CT, RTG) együttes elemzése alapján történik."
        }
    },
    homeopatia: {
        images: [require("../images/benefits/homepatia1.jpg"), require("../images/benefits/homepatia2.jpg")],
        en: {
            title: "HOMEOPATHY",
            disclamer: "The alternative cure, born in the time of Hippocrates, is based on the principle of 'similarity'. The precise elaboration of the method and the formulation of the medicaments were in the XVIII. At the end of the 20th century, it was due to Samuel Hahneman. Specially diluted, dynamized drugs from plant, animal and mineral ingredients are used in the treatments. The drug manufacturing technique guarantees that there are no side effects and the effectiveness is as wide as possible."
        },
        de: {
            title: "HOMÖOPATHIE",
            disclamer: "Homöopathie ist eine alternative Heilungsmethode, die in der Zeit von Hippokrates entwickelt wurde. Dies basiert auf dem „Ähnlichkeitsprinzip“. Samuel Hahnemann hat am Ende des XVIII. Jahrhunderts die genaue Methode der Homöopathie ausgearbeitet und die homöopathischen Medikamente entwickelt.Während der Behandlungen werden speziell verdünnte, dynamisierte Medikamente aus pflanzlichen, tierischen und mineralischen Grundstoffe verwendet.Durch die Herstellungstechnik dieser Medikamente wird garantiert, dass keine Nebenwirkungen auftreten werden und die Wirksamkeit dieser Medikamente nachhaltig wird. "
        },
        hu: {
            title: "HOMEOPÁTIA",
            disclamer: "Alternatív gyógymód, mely Hippokrates idején született, a „hasonlóság” elvén alapul. A módszer pontos kidolgozása és a gyógyszerek megalkotása a XVIII. század végén  Samuel Hahnemannak köszönhető. A kezelések során  növényi, állati és ásványi eredetű alapanyagokból  speciálisan higított,dinamizált gyógyszereket használunk. A gyógyszerek előállítási technikája garantálja, hogy ne  legyenek mellékhatások, és a hatékonyság a lehető legszélesebb legyen."
        }
    },
    vendegszobak: {
        images: [require("../images/benefits/vendegszoba1.jpg"), require("../images/benefits/vendegszoba2.jpg"), require("../images/benefits/vendegszoba3.jpg")],

        en: {
            title: "Guest Rooms",
            disclamer: "Our health center includes five suites on the first floor, which can accommodate overseas patients and possibly longer patients. All of our suites have hotel-level facilities and a private bathroom."
        },
        de: {
            title: "VENDÉGSZOBÁK",
            disclamer: "Egészségügyi Centrumunk részét képezi az emeleti  szinten található öt lakosztály, melyekben külföldi  pácienseink, esetleg hosszabb kezelésben részesülő  betegeink is szálláslehetőséghez juthatnak.  Minden lakosztályunk szállodai szintű felszereltséggel, saját fürdőszobával rendelkezik."
        },
        hu: {
            title: "GÄSTEZIMMER",
            disclamer: "Die fünf Suiten, die sich im Obergeschoss befinden, bilden einen Teil unseres Gesundheitszentrums, in denen die ausländischen Patienten bzw. Patienten, die eine längere Behandlungsdauer benötigen, unterbringt werden können. Alle unserer Suiten verfügen über eine Ausstattung auf Hotelebene und über ein eigenes Badezimmer. "
        }
    },
    fogtomes: {
        images: [require("../images/benefits/fogtomes1.jpg")],
        en: {
            title: "INLAY",
            disclamer: "Dental filling is one of the most common dental procedures used to restore decay tooth health. The advantage of the light-curing composite aesthetic tooth filling is that, in addition to its favorable mechanical properties, the teeth can be restored to their original color and shape."
        },
        de: {
            title: "ZAHNFÜLLUNG",
            disclamer: "Die Zahnfüllung ist eine der häufigsten zahnärztlichen Interventionen, die dem Zweck dient, kariöse Zähne zu versorgen. Der Vorteil der lichthärtenden ästhetischen Zahnfüllung aus Komposit ist, dass neben den günstigen mechanischen Eigenschaften die ursprüngliche Farbe und Gestalt der Zähne wiederhergestellt werden kann.  "
        },
        hu: {
            title: "FOGTÖMÉS",
            disclamer: "A fogtömés az egyik leggyakoribb fogászati beavatkozás, mely a szuvas fog épségének helyreállítására szolgál. A fényre kötő kompozit esztétikai fogtömés előnye , hogy kedvező mechanikai tulajdonságai mellett a fogak eredeti színe és alakja is visszaállítható."
        }
    },
    fogko: {
        images: [require("../images/benefits/fogko1.jpg"), require("../images/benefits/fogko2.jpg")],
        en: {
            title: "SCALE REMOVAL",
            disclamer: "The yellowish, calcified plaque above the gum is the tartar. Bacteria easily adhere to its surface, leading to gum bleeding, gingivit and bad breath. The tartar can be removed with an ultrasonic depurator or a number of hand tools that do not damage the enamel. The oscillatory movement of the device causes the tartar to bounce off the tooth surface and then polish it perfectly. The plain surface reduces the chance of plaque adhering, thus helping to preserve the tartar-free surface by regular brushing."
        },
        de: {
            title: "ZAHNSTEINENTFERNUNG",
            disclamer: "Unter Zahnstein versteht man der gelbe, verkalkte Zahnbelag, der sich auf dem Zahnfleisch bildet. An dessen Oberfläche haften die Bakterien leicht, die zu Zahnfleischbluten, Zahnfleischentzündung und Mundgeruch führen. Der Zahnstein kann mit Ultraschall-Depurator bzw. mit zahlreichen Handgeräten entfernt werden, die den Zahnschmelz nicht beschädigen. Durch die Schwingung des Gerätes prallt der Zahnstein von der Zahnoberfläche ab und danach wird die Zahnoberfläche glatt poliert. Auf der glatten Zahnoberfläche kann der Zahnbelag nur weniger gehaftet werden. Dadurch kann die zahnsteinfreie Oberfläche durch regelmäßiges Zähneputzen leichter bewahrt werden."
        },
        hu: {
            title: "FOGKŐ ELTÁVOLÍTÁS",
            disclamer: "Az íny felett elhelyezkedő sárgás színű, elmeszesedett lepedék a fogkő. Felszínén könnyen megtapadnak a baktériumok, melyek fogínyvérzéshez, ínygyulladáshoz, rossz lehelethez vezetnek. A fogkövet ultrahangos depurátorral, illetve számos kézi eszközökkel távolíthatjuk el, melyek a fogzománcot nem károsítják. A készülék rezgőmozgásának hatására a fogkövek lepattannak a fogfelszínről és ezután következik annak tökéletesen simára polírozása. A sima felszínen csökken a lepedék megtapadásának lehetősége, ezáltal könnyebben megőrizhetünk a fogkőmentes felszínt a rendszeres fogmosással."
        }
    },
    inlay: {
        images: [require("../images/benefits/inlay1.jpg")],
        en: {
            title: "INLAY",
            disclamer: "Inlay is a solid type of filling made by a dental technician rather than a doctor. It is advisable to prepare it when caries affect several tooth surfaces. in the tooth, the doctor prepares the location of the insert and then takes a precision imprint on the basis of which the dental technician prepares the insert. This insert is glued with a special adhesive into the prepared cavity by the doctor. Well-made inserts have a lifetime several times that of traditional fillings."
        },
        de: {
            title: "EINLAGEFÜLLUNG (INLAY)",
            disclamer: "Inlay ist eine feste Füllung, die nicht von einem Arzt, sondern von einem Zahntechniker angefertigt wird. Es ist empfehlenswert, ein Inlay anzufertigen, wenn mehrere Zähne von Karies betroffen sind. Der Zahnarzt bereitet im Zahn die Position des Inlays vor, danach nimmt er eine Präzisionsabformung und anhand dieser Abformung fertigt der Zahntechniker das Inlay an. Dieses Inlay wird vom Arzt mit einem speziellen Klebstoff in den vorbereiteten Hohlraum geklebt. Wenn die Inlays gut angefertigt sind, sie sind nachhaltiger als die herkömmlichen Füllungen."
        },
        hu: {
            title: "FOGBETÉT (INLAY)",
            disclamer: "Az inlay (magyarul: betét) olyan szilárd tömésfajta, amelyet nem az orvos, hanem a fogtechnikus készít el. Akkor célszerű készíteni, ha a szuvasodás több fogfelszínt érint. fogban az orvos előkészíti a betét helyét, majd precíziós lenyomatot vesz, melynek alapján a fogtechnikus elkészíti a betétet. Ezt a betétet az orvos speciális ragasztóval az előkészített üregbe beragasztja. A jól elkészített betétek élettartama többszöröse a hagyományos tömésekhez képest. "
        }
    },
    korona: {
        images: [require("../images/benefits/korona1.jpg"), require("../images/benefits/korona2.jpg")],
        en: {
            title: "Tooth Corona",
            disclamer: "Dental prosthesis covering the entire surface of the crown. When a missing tooth is crowned by placing a crown on the two tooth defining it, the missing teeth can be replaced, this is called a bridge. The crowns most often reach the edge of the gums, a so-called 'tooth' made by the dentist on the tooth. they are fixed on the shoulder, so that the crown passes through the tooth without a transition, which can prevent irritation of the gums and leave the replacement undetected. The crown and bridge replacement are made in several seats. For the first time, tooth preparation, tooth color determination and impression are performed. The next time you may need to test the bridge frame, the next time the finished dental bridge is put in your mouth. It is prepared by a dentist when there is little tooth tissue left due to tooth decay or injury. For incisors, it can also be made for aesthetic reasons."
        },
        de: {
            title: "ZAHNKRONE",
            disclamer: "Die Zahnkrone ist ein Zahnersatz, der die gesamte Zahnoberfläche bedeckt. Wenn ein Zahn fehlt, kann die Krone auf den beiden Zähnen, die den fehlenden Zahn begrenzen, durch die Krone ersetzt werden. Dies wird als Zahnbrücke bezeichnet. Die Kronen reichen meistens bis dem Zahnfleischrand. Sie sind an einer sogenannten „Schulter“ gefestigt, der vom Zahnarzt auf dem betroffenen Zahn angefertigt wird. Dadurch verläuft die Krone ohne Übergang in den Zahn und damit kann die Reizung des Zahnfleisches vermieden werden und der Ersatz kann nicht gemerkt werden. Die Krone und der Brückenersatz werden in mehreren Sitzungen angefertigt. Bei der ersten Sitzung wird die Zahnpräparation, die Bestimmung der Zahnfarbe und die Abdrucknahme durchgeführt. Bei der nächsten Sitzung kann benötigt werden, das Brückengerüst zu prüfen. Das nächste Mal wird die angefertigte Zahnbrücke in den Mund eingesetzt. Das wird von dem Zahnarzt in bestimmten Fällen angefertigt, wenn aufgrund von Karies oder Verletzungen nur wenige gesunde Zahngewebe vorhanden ist. Im Falle von Schneidezähnen kann diese auch aus ästhetischen Gründen angefertigt werden."
        },
        hu: {
            title: "KORONA",
            disclamer: "A Korona fog teljes felszínét borító fogpótlás. Hiányzó fog esetén a hiányt határoló két fogra koronát téve velük egyben elkészítve a hiányzó fogak pótolhatóak, ezt nevezzük hídnak. A koronák leggyakrabban a fogíny széléig érnek, egy, a fogorvos által a fogon készített un. vállon rögzülnek, így átmenet nélkül megy át a korona a fogba, minek következtében a fogíny irritációja elkerülhető, a pótlás pedig észrevétlen maradhat. A korona illetve hídpótlás több ülésben készül. Első alkalommal a fog előkészítése történik, valamint fogszín meghatározás és a lenyomatvétel. Következő alkalommal szükség lehet a hídváz próbájára, majd ezt követő alkalommal kerül a szájba a kész foghíd. Akkor készíti a fogorvos, ha fogszuvasodás vagy sérülés miatt kevés ép fogszövet maradt. Metszőfogak esetén esztétikai okból is készülhet. "
        }
    },
    endodoncia: {
        images: [require("../images/benefits/endodoncia1.png")],
        en: {
            title: "Endodontics",
            disclamer: "Endodontics is a specialist in the field of dentistry for the prevention, diagnosis and treatment of diseases of the gut and the tissues surrounding the tooth root. The tooth consists of a crown in the oral cavity and one or more roots in the jaws. The root fixes the teeth in the jawbone. The space inside the tooth (chamber and canal) is located inside the tooth, which contains the blood vessels and nerves that are needed for tooth development. During root treatment, the contents of the root canal are removed. Root canal treatment allows these teeth to be rescued, thus avoiding tooth removal."
        },
        de: {
            title: "ENDODONTOLOGIE",
            disclamer: "Endodontologie ist ein Fachgebiet in der Zahnmedizin, die sich mit der Prävention, Diagnose und Behandlung von Erkrankungen des Zahnmarks und der Gewebe befasst, die die Zahnwurzel umgeben. Der Zahn besteht aus einer Krone in der Mundhöhle und aus einer oder mehreren Wurzeln im Kiefer. Die Wurzel festigt die Zähne im Kieferknochen. Das Zahnmark befindet sich innerhalb des Zahnes (Kammer und Kanal), wo Gefäße und Nerven zu finden sind. Diese sind für die Zahnentwicklung verantwortlich. Während der Wurzelbehandlung wird der Inhalt des Wurzelkanals entfernt. Die Wurzelbehandlung ermöglicht diese Zähne zu retten, damit kann die Zahnentfernung vermieden werden. "
        },
        hu: {
            title: "ENDODONCIA",
            disclamer: "Az endodoncia a fogászatnak a fogbél és a foggyökeret körülvevő szövetek betegségeinek a megelőzésével, diagnózisával és kezelésével foglalkozó szakterülete. A fog a szájüregben látható koronából és egy vagy több az állcsontokban elhelyezkedő gyökérből áll. A gyökér rögzíti a fogakat az állcsontban. A fog belsejében található térben (kamra és csatorna) helyezkedik el a fogbél, mely ereket és idegeket tartalmaz, melyek a fogak fejlődéséhez szükségesek. A gyökérkezelés során a gyökércsatorna tartalmát eltávolítjuk. A gyökérkezelés teszi lehetővé ezen fogak megmentését, így a fog eltávolítása elkerülhető."
        }
    },
    fogfeherites: {
        images: [require("../images/benefits/fogfeherites1.jpg"), require("../images/benefits/fogfeherites2.jpg")],
        en: {
            title: "TOOTH WHITENING",
            disclamer: "In our practise, teeth whitening is performed using the American Zoom system, which removes discoloration from the teeth with a controlled oxidation and whitening mechanism. The process is amplified by a powerful blue light emitting lamp. With the Zoom Whitening System, relaxing in the comfort of your chair can make your smile appear brilliant white in just one hour. The result is immediately visible and takes approx. 6 shades of teeth whiten during treatment."
        },
        de: {
            title: "ZAHNAUFHELLUNG",
            disclamer: "In unserer Praxis wird die Zahnaufhellung mit Hilfe der amerikanischen Zoom Technik durchgeführt, damit die Zahnverfärbungen durch einen kontrollierten Oxidations- und Aufhellungsmechanismus entfernt werden können. Der Prozess wird durch eine leistungsstarke Polymerisationslampe verstärkt, die blaue Licht ausstrahlt. Mit Hilfe der Zoom Technik können die Zähne während der Relaxation im Behandlungsstuhl innerhalb von einer Stunde strahlend weiß werden. Das Ergebnis ist sofort sichtbar und die Zähne werden während der Behandlung bis zu 6 Nuancen weißer. "
        },
        hu: {
            title: "FOGFEHÉRÍTÉS",
            disclamer: "Rendelőnkben a fogfehérítést az amerikai Zoom rendszer segítségével végezzük, amely szabályozott oxidációs, fehérítő mechanizmussal távolítja el az elszíneződéseket a fogakról. A folyamatot egy nagy teljesítményű, kék fényt kibocsájtó lámpa erősíti fel. A Zoom fogfehérítő rendszer segítségével a kezelőszék nyugalmában relaxálva mindössze egy óra alatt ragyogó fehérré tudjuk varázsolni az Ön mosolyát. Az eredmény azonnal látható, és kb. 6 árnyalatnyit fehérednek a fogak a kezelés során."
        }
    },
    parodontoligia: {
        images: [require("../images/benefits/paradontologia1.jpg")],
        en: {
            title: "Periodontics",
            disclamer: "The periodontal specialist deals primarily with inflammation of the gums, periodontal and mucous membranes, their causes and their elimination. If the inflammation persists for a longer period of time, more serious dental treatments will be required as improperly maintained gum can easily lead to loss of teeth. In addition to tooth decay, tooth decay causes most tooth loss. It is important that the initial stage of the onset of gum disease is painful for very few people, but if it occurs, treatment should be taken seriously. We can avoid serious problems by going to the dental office regularly. Don't forget about proper oral care. Wash your teeth thoroughly at least twice a day, using dental floss or a toothbrush."
        },
        de: {
            title: "PARODONTOLOGIE",
            disclamer: "Der Facharzt für Parodontologie beschäftigt sich vor allem mit den Entzündungen des Zahnfleisches, des Zahnbettes und des Schleimhautes bzw. mit deren Ursachen und deren Beseitigung. Wenn die Entzündung über einen längeren Zeitraum anhält, sind ernsthaftere Zahnbehandlungen benötigt, da das falsch gepflegte Zahnbett leicht zum Zahnverlust führen kann. Zahnverlust kann nicht nur durch Karies, sondern auch durch Zahnbetterkrankungen verursacht werden. Bei der Entstehung der Zahnfleischerkrankungen ist wichtig zu erwähnen, dass diese im Frühstadium nur für sehr wenige Menschen schmerzhaft ist. Wenn dies jedoch auftritt, soll die Behandlung ernst genommen werden. Die ernsthaften Probleme können aber vermieden werden, wenn wir uns regelmäßig an zahnärztlichen Vorsorgeuntersuchungen teilnehmen. Die richtige Mundpflege soll auch nicht vergessen werden. Die Zähne sollen mindestens zweimal pro Tag gründlich gewaschen werden. Es ist auch empfehlenswert, Zahnseide oder Interdentalbürste zu benutzen."
        },
        hu: {
            title: "PARODONTOLÓGIA",
            disclamer: "A parodontológus szakorvos elsősorban az íny, a fogágy és a nyálkahártya gyulladásaival, azok okainak feltárásával és megszüntetésével foglalkozik. Ha a gyulladás hosszabb ideig fennáll akkor lesz szükség komolyabb fogászati kezelésekre is, hiszen a nem megfelelően karbantartott fogágy könnyen fogak elvesztéséhez vezethet. A fogszuvasodás mellett a fogágybetegésegek okozzák a legtöbb fogvesztést. Fontos , hogy a kezdeti stádium a fogínybetegségek kialakulása során csak nagyon kevés ember esetében jár fájdalommal, ha viszont megjelenik, akkor komolyan kell venni a kezelését. Elkerülhetjük a komolyabb gondokat, ha rendszeresen járunk fogászati kontrolra. Ne felejtsük el a megfelelő szájápolást sem. Minden nap legalább kétszer mossunk alaposan fogat, használjunk fogselymet, vagy fogköz tisztító kefét!"
        }
    },
    urulogia: {
        images: [],
        en: {
            title: "Urology",
            disclamer: "We provide a full range of urological diagnostic and examination services in specialized clinics, which can be followed by a hospital background. Diagnostic options: medical history, physical examination, ultrasound (kidney, bladder, prostate, testis) laboratory tests (urine, blood test, sperm test) bacterial cultures."
        },
        de: {
            title: "UROLOGIE",
            disclamer: "In der Sprechstunde wird eine ganzheitliche urologische Diagnose- und Untersuchungsmöglichkeit angeboten, wozu im Bedarfsfall auch krankenhäusliche Versorgung zur Verfügung steht. Diagnosemöglichkeiten: Krankengeschichte, Aufnahme der Krankengeschichte, körperliche Untersuchungen, Ultraschalluntersuchungen (Niere, Blase, Prostata und Hoden), Labortests (Urin, Blutprobe, Spermauntersuchung), Zucht von Bakterien. "
        },
        hu: {
            title: "UROLÓGIA",
            disclamer: "Szakrendelésen teljes körű urológiai diagnosztikus, kivizsgálási lehetőséget nyújtunk, melyhez szükség esetén kórházi háttér is biztosított. Diagnosztikus lehetőségek:anamnézis, kórelőzmény felvétele fizikális vizsgálatok ultrahangos vizsgálatok (vese, hólyag, prosztata, here) laboratóriumi vizsgálatok (vizelet, vérvizsgálat, spermavizsgálat) baktériumtenyésztések."
        }
    },
    panoramartg: {
        images: [require("../images/benefits/panoramartg1.jpg"), require("../images/benefits/panoramartg2.jpg")],
        en: {

        },
        de: {

        },
        hu: {

        }
    },
    telertg: {
        images: [require("../images/benefits/telertg1.jpg"), require("../images/benefits/telertg2.jpg")],
        en: {},
        de: {},
        hu: {}
    },
    ct: {
        images: [require("../images/benefits/tmjct1.jpg")],
        en: {},
        de: {},
        hu: {}
    },
    tmjct: {
        images: [require("../images/benefits/ct1.jpg"), require("../images/benefits/ct2.jpg")],
        en: {},
        de: {},
        hu: {}
    },
    uzemorvos: {
        images: [require('../images/doctors/deutchnorbert.jpg')],
        en: {
            title: deucthnorbert.en.role,
            disclamer: deucthnorbert.en.disclamer
        },
        de: {
            title: deucthnorbert.de.role,
            disclamer: deucthnorbert.de.disclamer
        },
        hu: {
            title: deucthnorbert.hu.role,
            disclamer: deucthnorbert.hu.disclamer
        }
    },
    borgyogyaszat: {
        images: [require('../images/benefits/borgyogyaszat.png')],
        en: {
            title: "Dermatology",
            disclamer: `General dermatological examination and treatment
                        Childhood skin care
                        Removal of viral warts
                        Treatment of elderly warts (seborrhoeic, actinic keratosis)
                        Treatment of fungal bacterial skin diseases
                        Treatment of eczema, rosacea, acne
                        Treatment of psoriasis
                        Investigation and treatment of hives
                        Nail and hair diseases
                        Mole filtering
                        Examination of skin tumors
                        Cosmetology`
        },
        de: {
            title: "DERMATOLOGIE",
            disclamer: `Allgemeine dermatologische Untersuchung und Behandlung
                        Behandlung von Hautkrankheiten im Kindesalter
                        Entfernung von Viruswarzen
                        Behandlung von Alterswarzen (seborrhoische, aktinische Keratose)
                        Behandlung von pilzlichen, bakteriellen Hautkrankheiten
                        Behandlung von Ekzem, Rosazea und Akne
                        Behandlung von Schuppenflechte
                        Untersuchung und Behandlung der Nesselsucht
                        Nagel- und Haarkrankheiten
                        Reihenuntersuchung von Geburtsmalen 
                        Untersuchung von Hauttumoren
                        Kosmetologie`
        },
        hu: {
            title: "Bőrgyógyászat",
            disclamer: `Általános bőrgyógyászati vizsgálat és kezelés <br>
                        Gyermekkori bőrbetegségek ellátása <br>
                        Vírusos szemölcsök eltávolítása <br>
                        Időskori szemölcsök (seborreás, aktinikus keratózis) kezelése <br>
                        Gombás, bakteriális bőrbetegségek kezelése <br>
                        Ekcéma, rosacea, akne kezélése <br>
                        Pikkelysömör kezelése <br>
                        Csalánkiütés kivizsgálása és kezelése <br>
                        Köröm- és hajbetegségek <br>
                        Anyajegy szűrés <br>
                        Bőrdaganatok vizsgálata <br>
                        Kozmetológia`
        }
    }
}