import React, { Fragment } from 'react';
import doctors from "../constant/doctors";
import higenikusok from "../constant/higenikus";
import szakaszisztens from '../constant/szakaszisztents';
import coordinators from '../constant/coordinators';

import { withNamespaces } from 'react-i18next';
import { isMobile, isTablet } from "react-device-detect"
function Doctors(props) {


    const { lng, t } = props;

    const lang = lng.split("-")[0];

    return (
        <div className="container" style={{ marginTop: 20 }}>
            {
                doctors.map(doctor => (
                    <div className={`${isMobile ? '' : 'row'}`} style={{
                        flexWrap: "nowrap", boxShadow: "1px 1px 1px 1px #888888",
                        margin: isMobile ? 0 : 20,
                        marginBottom: isMobile ? 20 : 0,
                        padding: isMobile ? 20 : 10,
                        width: "100%"
                    }}>
                        <div className="col-lg-4 col-xs-12" style={{
                            marginBottom: isMobile ? 20 : 0,
                        }}>
                            <img src={doctor.image} alt={doctor.name.toUpperCase()} style={{ maxHeight: 400, maxWidth: 266 }} />
                        </div>
                        <div className="col-lg-8 col-xs-12" >
                            <h2>{doctor.name.toUpperCase()}</h2>
                            <h3 style={{ wordBreak: "break-word", ...(isMobile ? { fontSize: 20 } : {}) }}>{doctor[lang].role.toUpperCase()}</h3>
                            <div>
                                {
                                    doctor[lang].boldDisclamer &&
                                    <div style={{ fontWeight: "bold" }}>
                                        {doctor[lang].boldDisclamer}
                                    </div>
                                }
                                {
                                    doctor[lang].disclamer &&
                                    <div>
                                        {doctor[lang].disclamer}
                                    </div>
                                }
                                {
                                    doctor[lang].listTitle &&
                                    <div>
                                        {
                                            doctor[lang].listTitle
                                        }
                                    </div>
                                }
                                {
                                    doctor[lang].listItems && doctor[lang].listItems.map(item =>

                                        <li>
                                            {item}
                                        </li>
                                    )
                                }
                            </div>
                        </div>
                    </div>


                ))
            }
            <div style={{ marginTop: 20 }}>
                <h3>{t('fogHigenikus')}</h3>
                <div>


                    <div className={`${isMobile || isTablet ? '' : 'row'}`} style={{
                        flexWrap: "nowrap", boxShadow: "1px 1px 1px 1px #888888",
                        margin: isMobile ? 0 : 20,
                        padding: isMobile ? 20 : 10,
                        width: "100%",
                        justifyContent: 'center'
                    }}>
                        {
                            higenikusok.map(hig => (
                                <Fragment>
                                    <div className="col-lg-3 col-sm-1 row" style={{ textAlign: 'center' }}>
                                        <img src={hig.image} alt={hig.name} style={{ maxHeight: 400, marginBottom: isMobile ? 20 : 0, maxWidth: 266, objectFit: "cover" }} />
                                        <h3>{hig.name.toUpperCase()}</h3>
                                    </div>

                                </Fragment>
                            ))
                        }
                    </div>

                </div>
            </div>

            <div style={{ marginTop: 20 }}>
                <h3>{t('fogSzakaszisztens')}</h3>
                <div>
                    <div className={`${isMobile || isTablet ? '' : 'row'}`} style={{
                        flexWrap: "wrap", boxShadow: "1px 1px 1px 1px #888888",
                        margin: isMobile || isTablet ? 0 : 20,
                        padding: isMobile || isTablet ? 20 : 10,
                        width: "100%",
                        justifyContent: 'center',
                        flexDirection: 'row'

                    }}>
                        {
                            szakaszisztens.map(hig => (

                                <div className="col-lg-3 col-sm-2">
                                    <img src={hig.image} alt={hig.name} style={{ maxHeight: 400, marginBottom: isMobile ? 20 : 0, height: 400, maxWidth: 266, width: 266, objectFit: "cover" }} />
                                    <h3 style={{ textAlign: 'center' }}>{hig.name.toUpperCase()}</h3>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div style={{ marginTop: 20 }}>
                <h3>{t('coordinators').toUpperCase()}</h3>
                <div>
                    <div className={`${isMobile || isTablet ? '' : 'row'}`} style={{
                        flexWrap: "wrap", boxShadow: "1px 1px 1px 1px #888888",
                        margin: isMobile || isTablet ? 0 : 20,
                        padding: isMobile || isTablet ? 20 : 10,
                        width: "100%",
                        justifyContent: 'center',
                        flexDirection: 'row'

                    }}>
                        {
                            coordinators.map(hig => (

                                <div className="col-lg-3 col-sm-2">
                                    <img src={hig.image} alt={hig.name} style={{ maxHeight: 400, marginBottom: isMobile ? 20 : 0, maxWidth: 266, width: 266 }} />
                                    <h3 style={{ textAlign: 'center' }}>{hig.name.toUpperCase()}</h3>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div>
                <h3>{t("ourTeam")}</h3>
                <div>
                    <div className={`${isMobile ? '' : 'row'}`} style={{
                        flexWrap: "nowrap", boxShadow: "1px 1px 1px 1px #888888",
                        margin: isMobile ? 0 : 20,
                        padding: isMobile ? 20 : 10,
                        width: "100%"
                    }}>
                        <img style={{ maxWidth: '100%' }} alt={t("ourTeam")} src={require('../images/team.jpg')} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withNamespaces()(Doctors)