import React, { Fragment, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import {
  faEnvelope, faPhoneAlt, faBars
} from "@fortawesome/free-solid-svg-icons";
import { isMobile } from 'react-device-detect';

import { withNamespaces } from 'react-i18next';
import i18n from "../i18n"
import MenuItems from './menuItems';

import { Link, withRouter } from "react-router-dom";

function Header({ setMenuOpen, t, setPopUpShown }) {

  const [isOpen, setOpen] = useState(false);

  return (
    <Fragment>
      <div className="top-bar" id="top-bar">
        {!isMobile && (
          <div
            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            id="footer-item-4"
            onClick={() => setPopUpShown(true)}
          >
            <img
              style={{ float: "right", position: 'relative', top: 0, right: 0 }}
              alt="szechenyi2020"
              src={require("../images/szecheny.png")}
            ></img>
          </div>
        )}
        <div className="container">

          <div className={"row"}>
            {isMobile && (
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                id="footer-item-4"
                onClick={() => setPopUpShown(true)}
              >
                <img
                  style={{ float: "right", position: 'static', top: 0, right: 0 }}
                  alt="szechenyi2020"
                  src={require("../images/szecheny.png")}
                ></img>
              </div>
            )}
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
              <div className="top-bar-adress">
                <i className="flaticon-navigation-arrow"></i> H-2013 Pomáz,
                Beniczky utca 4.
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
              <div className="top-bar-social">
                <a href="https://www.facebook.com/Fog-H%C3%A1z-Plussz-Kft-1730871997157745">
                  <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a style={{ marginLeft: 30 }} href=" " onClick={() => i18n.changeLanguage("hu")}>
                  <img style={{ width: 20 }} alt="hu" src={require("../images/flags/hu.png")} />
                </a>
                <a style={{ marginLeft: 5 }} href=" " onClick={() => i18n.changeLanguage("en")}>
                  <img style={{ width: 20 }} alt="en" src={require("../images/flags/en.png")} />
                </a>
                <a style={{ marginLeft: 5 }} href=" " onClick={() => i18n.changeLanguage("de")}>
                  <img style={{ width: 20 }} alt="de" src={require("../images/flags/de.png")} />
                </a>

              </div>
              <div className="top-bar-mail">
                <FontAwesomeIcon icon={faEnvelope} />
                <a href="mailto:recepcio@foghazplussz.hu">
                  recepcio@foghazplussz.hu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="header" id="header">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-2 col-sm-5 col-xs-12">
              <div className="header-logo" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <Link to="/">
                  <img src={require("../images/logo.png")} alt="" />
                </Link>
                <h3 style={{ textAlign: 'center' }}>Pomáz</h3>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-7 col-xs-12" style={{ justifyContent: "center", alignItems: "center", display: "flex" }}>

            </div>
            <div className="col-lg-4 col-md-4 col-sm-7 col-xs-12">
              <h3 style={{ textAlign: isMobile ? 'center' : 'right', marginTop: isMobile ? 20 : 0, marginRight: isMobile ? 0 : 20, float: isMobile ? 'unset' : 'right' }}>{t("bookApointment")}</h3>
              <div className="header-phone">
                <a href="tel:+3626343093">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  +36-26-343-093
                </a>
              </div>
              <div className="header-phone">
                <a href="tel:+36204331931">
                  <FontAwesomeIcon icon={faPhoneAlt} />
                  +36-20-433-1931
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="top-menu" id="top-menu">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="mobile-bar-cont">
                <div className="mobile-bar">
                  <div className="show-menu" id="show-menu" onClick={() => setMenuOpen(true)}>
                    <FontAwesomeIcon icon={faBars} onClick={() => setMenuOpen(true)} />
                  </div>
                </div>
              </div>
              <MenuItems isOpen={isOpen} />
            </div>
          </div>
        </div>
      </div>
    </Fragment >
  );
}

export default withNamespaces()(withRouter(Header));
