import React from 'react'

function Privacy() {
    return (
        <div className="container" style={{ marginTop: 20 }}>
            <div className="col s12">
                <p className="flow-text">
                    Az Európai Parlament és a Tanács 2016. április 27. napján 2016/679 számú rendeletet hozott
                    a természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről, és
                    az ilyen adatok szabad áramlásáról.( Általános Adatvédelmi Rendelet, angol elnevezéssel
                    GDPR, a továbbiakban Rendelet.) A Rendelet 2018. május 25. napján lép hatályba.

		</p><p className="flow-text">
                    A Rendeleten túlmenően a személyes adatok kezelésére alkalmazandó az információs
                    önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény ( Infotv.).
		</p><p className="flow-text">
                    Az egészségügyi adatok kezelésére vonatkozó speciális jogszabály az egészségügyi és a
                    hozzájuk kapcsolódó személyes adatok kezeléséről és védelméről szóló 1997. évi LXXVII.
                    törvény ( Eüatv.) és a végrehajtására kiadott 62/1997. (XII. 21.) NM rendelet.
		</p><p className="flow-text">
                    A FOG-HÁZ PLUSSZ Kft., mint adatkezelő, ( székhelye : 1039 Budapest, Hollós Korvin
                    L. utca 7. 6. em. 17., telephely: 2013 Pomáz, Beniczky u.4., cégjegyzékszám: 01-09-882853 ,
		adószám: 13977618-1-41, képviselő: Zoltai László ügyvezető, Telefon: <a href="tel:+3626343093">+36 26 343 093</a>,
		E-mail : <a href="mailto:recepcio@foghazplussz.hu">recepcio@foghazplussz.hu</a>
                    Honlap: <a href="https://www.foghazplussz.hu">www.foghazplussz.hu</a>, továbbiakban FOG-HÁZ
                    PLUSSZ Kft. ) a Rendelet előírásainak megfelelően, a jelen Adatvédelmi Tájékoztatóval tesz
                    eleget az érintettek irányában kötelező, közérthető tájékoztatási kötelezettségének.

		</p><h2>I. A FOG-HÁZ PLUSSZ Kft. adatkezelésének célja, jogalapja, adattovábbítás</h2>

                <ol>
                    <li><h3>Az adatkezelés célja</h3>
                        <p className="flow-text">
                            A FOG-HÁZ PLUSSZ Kft. adatkezelésének célja a tevékenységi körébe tartozó - az
                            egészség megőrzésének, javításának, fenntartásának előmozdítása, az érintett
                            eredményes gyógykezelése érdekében kötött -
                            szerződések teljesítése, mely
                            szerződésekben az érintett az egyik szerződő fél, vagy az adatkezelés a szerződés
                            megkötését megelőzően az érintett kérésére történő lépések megtételéhez szükséges.

			</p></li><li><h3>Az adatkezelés jogalapja</h3>
                        <p className="flow-text">
                            A FOG-HÁZ PLUSSZ Kft. tevékenysége során az alábbi jogalapra alapozva kezel
                            személyes adatot:
			</p><ul>
                            <li>- az érintett hozzájárulását adta személyes adatainak kezeléséhez,
				</li><li>- olyan szerződés teljesítése érdekében, amelyben az érintett az egyik fél, vagy
                                                                                    az a szerződés megkötését megelőzően az érintett kérésére történő lépések
                                                                                    megtételéhez szükséges ,
				</li><li>- jogszabály előírása teszi kötelezővé az adatkezelést.
			</li></ul>

                    </li><li><h3>A kezelt adatok köre, az adatkezelés időtartama</h3>
                        <p className="flow-text">
                            A FOG-HÁZ PLUSSZ Kft. az érintettek személyes és egészségügyi adatait kezeli.
                            Az adatkezelés időtartama
			</p><ul>
                            <li>- az érintett hozzájárulása alapján kezelt adatok esetében az érintett
                            hozzájárulásának visszavonásáig , illetve az adatkezelési cél megvalósulásáig,
				</li><li>- olyan szerződés teljesítése esetén, amelyben az érintett az egyik fél, az
                                                                                    érintettel kötött szerződés teljesítését követő 5 év.
				</li><li>- jogszabályi előírás alapján megvalósuló adatkezelés esetén a jogszabályban
                                                                                    előírt megőrzési idő.
			</li></ul>

                    </li><li><h3>Adattovábbítás, adatvédelem</h3>
                        <p className="flow-text">
                            A FOG-HÁZ PLUSSZ Kft. a tudomány és technológia állását kellően figyelembe
                            véve gondoskodik arról, hogy kívülálló ne férhessen az általa kezelt adatokhoz. A
                            rendelkezésére álló műszaki, informatikai eszközök használatával megtesz minden
                            óvintézkedést a személyes adatok elvesztése, sérülése, illetéktelen hozzáférés ellen.
                            A papír alapon tárolt személyes adatokat zárt irattartó szekrényben, az elektronikusan
                            tárolt adatokat tűzfallal, jelszóval védett számítógépeken tárolja.

			</p><p className="flow-text">
                            Azon adatfeldolgozók, akik részére jogszerűen továbbítja a személyes adatokat,
                            szerződéses kötelezettséget vállalnak az adatok megőrzése érdekében.

			</p><p className="flow-text">
                            A FOG-HÁZ PLUSSZ Kft. az általa kezelt, jelen tájékoztatóban megjelölt adatokat az
                            érintett hozzájárulása vagy jogszabályi kötelezettség alapján ( pl. Eüatv. 23. §.)
                            továbbítja harmadik fél részére.
		</p></li><p></p><p></p><p></p></ol>

                <h2>II. Adatvédelmi tisztviselő</h2>
                A FOG-HÁZ PLUSSZ KFT. adatvédelmi tisztviselő alkalmazására nem köteles.

		<h2>III. Az érintettek jogai</h2>
                Az érintettek főbb jogai a következők:
		<ol type="a">
                    <li>a rá vonatkozó személyes adatokhoz való hozzáférés;
		</li><li>azok helyesbítése;
		</li><li>törlése („az elfeledtetéshez való jog”);
		</li><li>kezelésének korlátozása;
		</li><li>az adathordozhatósághoz való jog.
		</li><li>felügyeleti hatósághoz címzett panasz benyújtásának joga
		</li></ol>

                <ol type="a">
                    <li>Az érintett jogosult arra, hogy az adatkezelőtől visszajelzést kapjon arra vonatkozóan, hogy
                    személyes adatainak kezelése folyamatban van-e, és ha ilyen adatkezelés folyamatban van,
                    jogosult arra, hogy a személyes adatokhoz és a következő információkhoz hozzáférést kapjon
			<ul>
                            <li>az adatkezelés céljai;
				</li><li>az érintett személyes adatok kategóriái;
				</li><li>azon címzettek vagy címzettek kategóriái, akikkel, illetve amelyekkel a személyes
                                                                                    adatokat közölték vagy közölni fogják, ideértve különösen a harmadik országbeli
                                                                                    címzetteket, illetve a nemzetközi szervezeteket;
				</li><li> adott esetben a személyes adatok tárolásának tervezett időtartama, vagy ha ez nem
                                                                                    lehetséges, ezen időtartam meghatározásának szempontjai;
			</li></ul>
                    </li><li>Az érintett kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatok helyesbítését,
                    amennyiben azok pontatlanok.
			</li><li> Az érintett kérheti személyes adatainak törlését, ha az alábbi indokok valamelyike fennáll:
				<ul>
                            <li> a személyes adatokra már nincs szükség abból az okból, amelyből azokat gyűjtötték,
					</li><li> az érintett tiltakozik az adatkezelés ellen, és nincs elsőbbséget élvező jogszerű ok az
                                                            adatkezelésre,
					</li><li> a személyes adatokat jogellenesen kezelték,
					</li><li> a személyes adatokat az adatkezelőre alkalmazandó uniós vagy tagállami jogban előírt
                                                                    kötelezettség miatt törölni kell,
				</li></ul>

                    </li><li>Az érintett jogosult arra, hogy kérésére az adatkezelő korlátozza az adatkezelést, ha az
                            alábbiak valamelyike teljesül: az érintett vitatja a személyes adatok pontosságát, ez esetben a
                            korlátozás arra az időtartamra vonatkozik, amely lehetővé teszi, hogy az adatkezelő
                            ellenőrizze a személyes adatok pontosságát.
			</li><li>Az adathordozhatósághoz való jog alapján az érintett jogosult arra, hogy a rá vonatkozó,
                                                    általa a FOG-HÁZ PLUSSZ Kft. rendelkezésére bocsátott személyes adatokat géppel
                                                    olvasható formátumban megkapja és ezeket az adatokat egy másik adatkezelőnek továbbítsa
                                                    anélkül, hogy ezt akadályozná a FOG-HÁZ PLUSSZ Kft.
		</li></ol>

                <p></p><p className="flow-text">
                    Az érintettek ezen jogaikat írásban, postai úton a FOG-HÁZ PLUSSZ Kft. székhelyére
                    címzett levélben, vagy a FOG-HÁZ PLUSSZ Kft. e-mail címére küldött elektronikus levélben
                    gyakorolhatják. Az elérhetőségeket a Tájékoztató bevezetője tartalmazza.

		</p><p className="flow-text">
                    A FOG-HÁZ PLUSSZ Kft. az érintett kérelmére indokolatlan késedelem nélkül, de
                    mindenféleképpen a kérelem beérkezésétől számított egy hónapon belül tájékoztatja az
                    érintettet. Szükség esetén, figyelembe véve a kérelem összetettségét és a kérelmek számát, ez
                    a határidő további két hónappal meghosszabbítható.

		</p><h2>IV. Jogorvoslati lehetőségek</h2>
                <p></p><p className="flow-text">
                    Amennyiben az érintett az adatkezelés jogszerűtlenségét vélelmezi, lehetősége van ezt
                    írásban jelezni a recepcio@foghazplussz.hu e-mail címen, vagy 1039 Budapest, Hollós Korvin
                    L. utca 7. 6. em. 17.postai címre.
                    Az irányadó jogszabályok szerint jogorvoslattal élhet a hatáskörrel rendelkező szerveknél:
		</p><ul>
                    <li>- Nemzeti Adatvédelmi és Információszabadság Hivatalnál (cím: 1125 Budapest,
                        Szilágyi Erzsébet fasor 22/c, postacím: 1530 Budapest, Pf.: 5., telefon:
				<a href="tel:+3613911400">+36 (1) 391-1400</a>, fax: +36 (1) 391-1410,
				e-mail: <a href="mailto:ugyfelszolgalat@naih.hu">ugyfelszolgalat@naih.hu</a>)
			</li><li>- Illetékes bíróságnál.
		</li></ul>

                <p></p></div>
        </div>
    )
}

export default Privacy
