/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { withNamespaces } from 'react-i18next';
import { isMobile } from 'react-device-detect';

function PopupContent({ onClose, t }) {
  return (
    <div style={{ overflow: "scroll", textAlign: "center", display: 'flex', flexDirection: 'column', maxWidth: '100%', width: isMobile ? '100%' : undefined }}>
      <a className="close" onClick={() => onClose(false)} style={{ alignSelf: 'flex-end' }}>
        &times;
      </a>

      <img src={require('../images/karacsony-2021.png')} alt="boldog karácsonyt"></img>
    </div >
  );
}

export default withNamespaces()(PopupContent);
