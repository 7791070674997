/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { isMobile } from 'react-device-detect';
function MenuItems(props) {

  const {
    location: { pathname }, t, isOpen
  } = props;



  return (
    <ul className="nav navbar-nav" style={{ display: `${isMobile ? (isOpen ? 'flex' : 'none') : 'flex'}` }}
    >
      <li className={`${pathname === "/" ? "active" : ""}`}>
        <Link to="/">{t("homepage")}</Link>
      </li>

      <li
        className={`dropdown ${pathname === "/services" ? "active" : ""
          }`}
      >
        <a data-toggle="dropdown">{t("benefits")}</a>
        <ul className="dropdown-menu with-bg" role="menu">
          <li>
            <Link to="/services/fogaszat">{t("dentistry")}</Link>
            <ul>
              <li>
                <Link to="/services/fogtomes">{t("toothFillings")}</Link>
              </li>
              <li>
                <Link to="/services/fogko">{t("scaleRemoval")}</Link>
              </li>
              <li>
                <Link to="/services/inlay">{t("inlay")}</Link>
              </li>
              <li>
                <Link to="/services/korona">{t("crown")}</Link>
              </li>
              <li>
                <Link to="/services/endodoncia">{t("endodontics")}</Link>
              </li>
              <li>
                <Link to="/services/korona">{t("restorations")}</Link>
              </li>
              <li>
                <Link to="/services/fogfeherites">{t("whitening")}</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/services/rontgen">{t("xray")}</Link>
            <ul>
              <li>
                <Link to="/services/panoramartg">{t("digitalPanoXray")}</Link>
              </li>
              <li>
                <Link to="/services/telertg">{t("digitalFullXray")}</Link>
              </li>
              <li>
                <Link to="/services/ct">{t("digitalPeriapicalXray")}</Link>
              </li>
              <li>
                <Link to="/services/tmjct">{t("tmjCT")}</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to="/services/szajsebeszet">{t("oralSurgery")}</Link>
          </li>
          <li>
            <Link to="/services/fogszabalyozas">{t("orthodontics")}</Link>
          </li>
          <li>
            <Link to="/services/parodontoligia">{t("periodontics")}</Link>
          </li>
          <li>
            <Link to="/services/homeopatia">
              {t("homeopathy")}
            </Link>
          </li>
          <li>
            <Link to="/services/ortopedia">{t("orthopedy")}</Link>
          </li>
          <li>
            <Link to="/services/borgyogyaszat">{t("dermatology")}</Link>
          </li>
          <li>
            <Link to="/services/urulogia">
              {t("urology")}
            </Link>
          </li>
          <li>
            <Link to="/services/gyneurologia">
              {t("childNeurology")}
            </Link>
          </li>
          <li>
            <Link to="/services/uzemorvos">{t("occupationalPhysician")}</Link>
          </li>
          <li>
            <Link to="/services/vendegszobak"> {t("guestrooms")}</Link>
          </li>
        </ul>
      </li>
      <li className={`${pathname === "/doctors" ? "active" : ""}`}>
        <Link to="/doctors">
          {t("ourDoctors")}
        </Link>
      </li>

      <li className={`${pathname === "/prices" ? "active" : ""}`}>
        <Link to="/prices">{t("priceList")}</Link>
      </li>
      <li className={`${pathname === "/warranty" ? "active" : ""}`}>
        <Link to="/warranty">
          {t("warranty")}
        </Link>
      </li>
      <li>
        <a href="http://caminomozgaskozpont.hu" target="_blank" rel="noopener noreferrer">
          Camino
        </a>
      </li>
      <li className={`${pathname === "/contacts" ? "active" : ""}`}>
        <Link to="/contacts">{t("contacts")}</Link>
      </li>
    </ul>
  )
}

export default withNamespaces()(withRouter(MenuItems));
