export default [
    {
        title: "Adoszt Egészségpénztár",
        img: require("../images/partners/adoszt.jpg")
    },
    {
        title: "Aranykor Egészségpénztár",
        img: require("../images/partners/aranykor.jpg")
    }, {
        title: "AXA Egészségpénztár",
        img: require("../images/partners/axa.jpg")
    }, {
        title: "Dimenzió Egészségpénztár",
        img: require("../images/partners/dimenzio.jpg")
    }, {
        title: "Generali Egészségpénztár",
        img: require("../images/partners/generali.png")
    }, {
        title: "Honvéd Egészségpénztár",
        img: require("../images/partners/honved.png")
    }, {
        title: "Izys Egészségpénztár",
        img: require("../images/partners/izys.png")
    }, {
        title: "K&H Egészségpénztár",
        img: require("../images/partners/kh.png")
    }, {
        title: "MediSmart",
        img: require("../images/partners/medismart.png")
    }, {
        title: "MKB Egészségpénztár",
        img: require("../images/partners/mkb.jpg")
    }, {
        title: "OTP Egészségpénztár",
        img: require("../images/partners/otp.jpg")
    }, {
        title: "Patika Egészségpénztár",
        img: require("../images/partners/patika.png")
    }, {
        title: "Postás Egészségpénztár",
        img: require("../images/partners/postas.png")
    }, {
        title: "Pro Vita Egészségpénztár",
        img: require("../images/partners/provita.png")
    }, {
        title: "TEMPO Egészségpénztár",
        img: require("../images/partners/tempo.png")
    }, {
        title: "Ticket Wellness",
        img: require("../images/partners/ticket-wellness.jpg")
    }, {
        title: "Új Pillér Egészségpénztár",
        img: require("../images/partners/ujpiller.jpg")
    }, {
        title: "Vasutas Egészségpénztár",
        img: require("../images/partners/vasutas.jpg")
    }
]