import React from 'react'

export default function Warranty() {
  return (
    <div className='container' style={{ marginTop: 40 }}>
      <p style={{ "font-weight": 400 }}><strong>A Fog-H&aacute;z Plussz Kft. a p&aacute;cienseinek ny&uacute;jtott szolg&aacute;ltat&aacute;sokkal kapcsolatban az al&aacute;bbi garanci&aacute;kat v&aacute;llalja:</strong></p>
      <p style={{ "font-weight": 400 }}>&ndash; Fix p&oacute;tl&aacute;sokn&aacute;l (korona, h&iacute;d): f&eacute;mker&aacute;mia 2 &eacute;v, cirk&oacute;nium 2 &eacute;v.</p>
      <p style={{ "font-weight": 400 }}>&ndash; Kivehető p&oacute;tl&aacute;sokn&aacute;l: r&eacute;szleges fogsor 1 &eacute;v, teljes fogsor 1 &eacute;v.</p>
      <p style={{ "font-weight": 400 }}>&ndash; Implant&aacute;ci&oacute; eset&eacute;n (az implant&aacute;tum szerkezeti elemire): 2 &eacute;v.</p>
      <p style={{ "font-weight": 400 }}><u>Csontosod&aacute;si garancia</u>, ha a be&uuml;ltet&eacute;s ut&aacute;n az implant&aacute;tum nem csontosodik be, begyullad, vagy csontosod&aacute;s helyett k&ouml;tősz&ouml;vetesen r&ouml;gz&uuml;l akkor ingyen be&uuml;ltet&uuml;nk egy m&aacute;sikat. Amennyiben a sikeresen becsontosodott implant&aacute;tum k&ouml;r&uuml;l k&eacute;sőbb le&eacute;p&uuml;l, begyullad a csont &eacute;s kilazul/kiesik, az m&aacute;r nem tartozik a garancia hat&aacute;lya al&aacute;, hiszen a p&aacute;ciens eg&eacute;szs&eacute;gi &aacute;llapota, betegs&eacute;gek, gy&oacute;gyszerek, kezel&eacute;sek, doh&aacute;nyz&aacute;s, sz&aacute;jhigi&eacute;nia okozhatja.</p>
      <p style={{ "font-weight": 400 }}>&ndash; Fogt&ouml;m&eacute;sn&eacute;l: 6 h&oacute;nap garanci&aacute;t v&aacute;llalunk.</p>
      <p style={{ "font-weight": 400 }}>&ndash; A garanciav&aacute;llal&aacute;s (j&oacute;t&aacute;ll&aacute;s) nem terjed ki az ideiglenes megold&aacute;sokra, a &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;koron&aacute;val/h&iacute;ddal ell&aacute;tott fogak k&eacute;sőbbi gy&ouml;k&eacute;rkezel&eacute;s&eacute;re, a p&aacute;ciens szervezet&eacute;nek term&eacute;szetes reakci&oacute;i, a nem rendeltet&eacute;sszerű haszn&aacute;lat, vagy a nem megfelelő sz&aacute;jhigi&eacute;nia &aacute;ltal okozott előre nem l&aacute;that&oacute; k&ouml;vetkezm&eacute;nyekre.</p>
      <p style={{ "font-weight": 400 }}><strong>&nbsp;</strong></p>
      <p style={{ "font-weight": 400 }}><strong>&nbsp;Garancia &eacute;rv&eacute;nyes&iacute;t&eacute;s&eacute;nek felt&eacute;tele: </strong>a megfelelő sz&aacute;jhigi&eacute;nia, valamint a 3h&oacute;nap/f&eacute;l&eacute;v/&eacute;ves rendszeres kontrollvizsg&aacute;lat, melynek dokument&aacute;l&aacute;s&aacute;t az Fog-H&aacute;z Plussz Kft. &nbsp;kezelőorvosa v&eacute;gzi.</p>
      <p style={{ "font-weight": 400 }}>A garancia nem &eacute;rv&eacute;nyes amennyiben a p&aacute;ciens:</p>
      <ul>
        <li>nem jelenik meg a sz&aacute;m&aacute;ra elő&iacute;rt kontrollvizsg&aacute;laton. nem tartja be a fogorvos &aacute;ltal javasolt sz&aacute;jhigi&eacute;niai elő&iacute;r&aacute;sokat.</li>
        <li>a fogművet, fogp&oacute;tl&aacute;st nem rendeltet&eacute;sszerűen haszn&aacute;lja (leejti, oda&uuml;ti, stb.).</li>
        <li>a fogorvos &aacute;ltal javasolt &eacute;jszakai harap&aacute;slaz&iacute;t&oacute; s&iacute;nt nem k&eacute;sz&iacute;tteti el, illetve nem hordja, melynek k&ouml;vetkezm&eacute;nye a t&uacute;lzott fogsor szor&iacute;t&aacute;sb&oacute;l ad&oacute;d&oacute; porcel&aacute;n leplez&eacute;s lepattan&aacute;sa.</li>
        <li>a porcel&aacute;n h&eacute;jat megfesz&iacute;ti, &eacute;s ez&aacute;ltal k&aacute;r keletkezik benne</li>
        <li>baleset sor&aacute;n szerzett s&eacute;r&uuml;l&eacute;seket.</li>
      </ul>
      <p style={{ "font-weight": 400 }}>&nbsp;</p>
      <p style={{ "font-weight": 400 }}><strong>Garancia gy&ouml;k&eacute;rkezel&eacute;sekre</strong> <strong>nem adhat&oacute;</strong>. A gy&ouml;k&eacute;rkezel&eacute;s az anat&oacute;miai elt&eacute;r&eacute;sek &eacute;s egyedi vari&aacute;ci&oacute;k nagy sz&aacute;ma miatt a leggondosabb fogorvosi beavatkoz&aacute;s mellett sem garant&aacute;lhat&oacute; 100%-osan.&nbsp;</p>
      <p style={{ "font-weight": 400 }}>IDŐPONTLEMOND&Aacute;SSAL KAPCSOLATOS INFORM&Aacute;CI&Oacute;K</p>
      <p style={{ "font-weight": 400 }}>K&eacute;rj&uuml;k, figyeljenek előre egyeztetett időpontjaikkal kapcsolatban felmer&uuml;lő esetleges probl&eacute;m&aacute;k eset&eacute;n azok időben t&ouml;rt&eacute;nő lemond&aacute;s&aacute;ra. 24 &oacute;r&aacute;n bel&uuml;li lemond&aacute;s eset&eacute;n 10 000 Ft k&eacute;szenl&eacute;ti d&iacute;jat vagyunk k&eacute;nytelenek felsz&aacute;molni. &Iacute;dőpont lemond&aacute;st k&eacute;rj&uuml;k e-mailben vagy SMS form&aacute;ban jelezz&eacute;k nek&uuml;nk.</p>
      <p style={{ "font-weight": 400 }}>&nbsp;</p>
      <p style={{ "font-weight": 400 }}>E-mail c&iacute;m: recepcio@foghazplussz.hu</p>
      <p style={{ "font-weight": 400 }}>&nbsp;</p>
      <p style={{ "font-weight": 400 }}>Telefonsz&aacute;m : <a href="tel:+36204331931">+36-20-433-1931</a></p>
      <p style={{ "font-weight": 400 }}>&nbsp;</p>
      <p style={{ "font-weight": 400 }}>&nbsp;</p>
      <p style={{ "font-weight": 400 }}>&nbsp;</p>
      <div>&nbsp;</div>
    </div>
  )
}
